import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Signal, computed, inject, input, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, Observable, startWith, switchMap } from "rxjs";
import { LicenseTypes, Subsidiary } from "shield.shared";
import { Contact } from "src/app/entity-models/contact.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Product } from "src/app/entity-models/product.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { SubsidiaryTypes } from "src/app/enums/subsidiary-types";
import { Helper } from "src/app/helpers/helper";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";
import { StateLicenseDelineationService } from "src/app/services/delineation-services/state-license-delineation.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

type ProductTax = {
    stateTaxAmount: number;
    countyTaxAmount: number;
    cityTaxAmount: number;
    statePrepaid: boolean;
};
type CallProduct = {
    productId: string;
    quantity: number;
    price?: number;
    value?: number;
    discount?: number;
    callProductTax?: ProductTax;
    total: number;
};
type CallProductWithProductAndTotal = CallProduct & {
    product: Product;
    totalWithTax: number;
};
@Component({
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailBaseComponent implements OnInit {
    private stateService = inject(StateDelineationService);
    private stateLicenseService = inject(StateLicenseDelineationService);
    elementRef = inject(ElementRef);
    customer = input.required<Customer>();
    @Input("call") call: Observable<RetailCall | RmWholesaleCall>;
    callSignal = signal<RetailCall | RmWholesaleCall>(null);
    employee = input.required<Employee>();
    contact = input.required<Contact>();
    receiptNumberExtention = input.required<string>();
    activeProducts = input.required<Map<string, Product>>();
    signature = input<string>();
    subsidiary: Signal<Subsidiary>;
    swisherAddress = "P.O. Box 2230, Jacksonville, FL 32203";
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;

    ngOnInit() {
        this.call.subscribe((call) => {
            this.callSignal.set(call);
        });
    }

    private cashProducts = computed(() => {
        const call = this.callSignal();
        const cashProducts = call?.cashProducts.map<CallProductWithProductAndTotal>((cashProduct) => {

            return {
                ...cashProduct,
                product: this.activeProducts().get(cashProduct.productId),
                total: cashProduct.quantity * (cashProduct.price - cashProduct.discount),
                totalWithTax: addTaxToTotal(cashProduct.quantity * (cashProduct.price - cashProduct.discount), cashProduct.callProductTax)
            };
        });
        return cashProducts ?? [];
    });
    private gratisProducts = computed(
        () =>
            this.callSignal()?.gratisProducts.map<CallProductWithProductAndTotal>((gratisProduct) => ({
                ...gratisProduct,
                product: this.activeProducts().get(gratisProduct.productId),
                total: gratisProduct.quantity * gratisProduct.value,
                totalWithTax: addTaxToTotal(gratisProduct.quantity * gratisProduct.value, gratisProduct.callProductTax)
            })) ?? []
    );
    private exchangeInProducts = computed(
        () =>
            this.callSignal()?.exchangeInProducts.map<CallProductWithProductAndTotal>((exchangeInProduct) => ({
                ...exchangeInProduct,
                product: this.activeProducts().get(exchangeInProduct.productId),
                total: exchangeInProduct.quantity * exchangeInProduct.price,
                totalWithTax: addTaxToTotal(exchangeInProduct.quantity * exchangeInProduct.price, exchangeInProduct.callProductTax)
            })) ?? []
    );
    private exchangeOutProducts = computed(
        () =>
            this.callSignal()?.exchangeOutProducts.map<CallProductWithProductAndTotal>((exchangeOutProduct) => ({
                ...exchangeOutProduct,
                product: this.activeProducts().get(exchangeOutProduct.productId),
                total: exchangeOutProduct.quantity * exchangeOutProduct.price,
                totalWithTax: addTaxToTotal(exchangeOutProduct.quantity * exchangeOutProduct.price, exchangeOutProduct.callProductTax, -1)
            })) ?? []
    );
    salesRepName = computed(() => `${this.employee().lastName}, ${this.employee().firstName} `);
    contactName = computed(() => {
        const contact = this.contact();
        return (contact?.lastName ? contact?.lastName + ", " : "") + (contact?.firstName ? contact?.firstName : "");
    });
    zrtAndSalesRepName = computed(() => `${this.employee().zrt ? `ZRT ${this.employee().zrt}, ` : ""}${this.salesRepName()}`);
    time = computed(() => this.callSignal()?.stopTime ?? new Date());
    state = toSignal(
        toObservable(this.customer).pipe(
            switchMap((customer) => this.stateService.getByShortCode(customer.businessAddress.state)),
            map((state) => state.values),
            startWith({ shortCode: "", name: "" })
        )
    );
    private stateLicenses = toSignal(
        toObservable(this.customer).pipe(
            switchMap((customer) => this.stateLicenseService.getByShortCode(customer.businessAddress.state)),
            map((stateLicenses) => stateLicenses.values),
            startWith([])
        )
    );

    formattedAddress = computed(() => {
        const address = this.customer().businessAddress;
        return `${address.address1} ${address.address2 ? address.address2 : ""}, ${address.city}, ${address.state} ${address.zip}`;
    });

    receiptNumber = computed(() => {
        const receiptNumberExtention = this.receiptNumberExtention();
        const call = this.callSignal();
        const receiptNumber = call.callReceipts.find((cr) => cr.receiptNumberExtention === receiptNumberExtention)?.receiptNumber;
        return receiptNumber ?? `${this.employee().zrt}${call.receiptBase}`;
    });

    stateOptLicense = computed(() =>
        this.customer().customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive)
    );
    stateVaporLicense = computed(() =>
        this.customer().customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive)
    );

    swisherLicense = computed(() => this.stateLicenses().find((sl) => sl.subsidiaryId === Subsidiary.Swisher)?.number ?? "");
    easLicense = computed(() => this.stateLicenses().find((sl) => sl.subsidiaryId === Subsidiary.EAS)?.number ?? "");

    swisherCashProducts = computed(() => this.cashProducts().filter(filterSwisher));
    swisherTaxableCashProducts = computed(() =>
        this.swisherCashProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    swisherGratisProducts = computed(() => this.gratisProducts().filter(filterSwisher));
    swisherTaxableGratisProducts = computed(() =>
        this.swisherGratisProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    swisherExchangeOutProducts = computed(() => this.exchangeOutProducts().filter(filterSwisher));
    swisherTaxableExchangeOutProducts = computed(() =>
        this.swisherExchangeOutProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    swisherExchangeInProducts = computed(() => this.exchangeInProducts().filter(filterSwisher));
    swisherTaxableExchangeInProducts = computed(() =>
        this.swisherExchangeInProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    swisherTaxableProducts = computed(() =>
        [
            ...this.swisherTaxableCashProducts(),
            ...this.swisherTaxableGratisProducts(),
            ...this.swisherTaxableExchangeOutProducts(),
            ...this.swisherTaxableExchangeInProducts()
        ].map((product) => ({
            productDescription: product.product.description,
            stateTax: product.callProductTax?.stateTaxAmount ?? 0,
            countyTax: product.callProductTax?.countyTaxAmount ?? 0,
            cityTax: product.callProductTax?.cityTaxAmount ?? 0,
            total:
                ((product.callProductTax?.stateTaxAmount ??
                0) + (product.callProductTax?.countyTaxAmount ??
                0) + (product.callProductTax?.cityTaxAmount ??
                0))
        }))
    );

    swisherStateTaxTotal = computed(() => this.swisherTaxableProducts().reduce((sum, product) => sum + product.stateTax, 0));
    swisherCountyTaxTotal = computed(() => this.swisherTaxableProducts().reduce((sum, product) => sum + product.countyTax, 0));
    swisherCityTaxTotal = computed(() => this.swisherTaxableProducts().reduce((sum, product) => sum + product.cityTax, 0));
    swisherTaxTotal = computed(() => this.swisherStateTaxTotal() + this.swisherCountyTaxTotal() + this.swisherCityTaxTotal());

    easCashProducts = computed(() => this.cashProducts().filter(filterEas));
    easTaxableCashProducts = computed(() =>
        this.easCashProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    easGratisProducts = computed(() => this.gratisProducts().filter(filterEas));
    easTaxableGratisProducts = computed(() =>
        this.easGratisProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    easExchangeOutProducts = computed(() => this.exchangeOutProducts().filter(filterEas));
    easTaxableExchangeOutProducts = computed(() =>
        this.easExchangeOutProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    easExchangeInProducts = computed(() => this.exchangeInProducts().filter(filterEas));
    easTaxableExchangeInProducts = computed(() =>
        this.easExchangeInProducts().filter(
            (cp) => cp.callProductTax?.stateTaxAmount || cp.callProductTax?.countyTaxAmount || cp.callProductTax?.cityTaxAmount
        )
    );
    easTaxableProducts = computed(() =>
        [
            ...this.easTaxableCashProducts(),
            ...this.easTaxableGratisProducts(),
            ...this.easTaxableExchangeOutProducts(),
            ...this.easTaxableExchangeInProducts()
        ].map((product) => ({
            productDescription: product.product.description,
            stateTax: product.callProductTax?.stateTaxAmount ?? 0,
            countyTax: product.callProductTax?.countyTaxAmount ?? 0,
            cityTax: product.callProductTax?.cityTaxAmount ?? 0,
            total:
                ((product.callProductTax?.stateTaxAmount ??
                0) + (product.callProductTax?.countyTaxAmount ??
                0) + (product.callProductTax?.cityTaxAmount ??
                0))
        }))
    );

    taxByType = (products:CallProductWithProductAndTotal[]) => {
        return products.reduce((a, product) => {
            // a += ((product.callProductTax?.statePrepaid ? 0 : product.callProductTax?.stateTaxAmount ?? 0) + (product.callProductTax?.countyTaxAmount ?? 0) + (product.callProductTax?.cityTaxAmount ?? 0));
            a += ((product.callProductTax?.stateTaxAmount ?? 0) + (product.callProductTax?.countyTaxAmount ?? 0) + (product.callProductTax?.cityTaxAmount ?? 0));
            return a;
        }, 0)
    }

    easStateTaxTotal = computed(() => this.easTaxableProducts().reduce((sum, product) => sum + product.stateTax, 0));
    easCountyTaxTotal = computed(() => this.easTaxableProducts().reduce((sum, product) => sum + product.countyTax, 0));
    easCityTaxTotal = computed(() => this.easTaxableProducts().reduce((sum, product) => sum + product.cityTax, 0));
    easTaxTotal = computed(() => this.easStateTaxTotal() + this.easCountyTaxTotal() + this.easCityTaxTotal());

    swisherCashDueNow = computed(() => calculateCashDueNow(this.swisherCashProducts()));
    swisherTaxableCashDueNow = computed(() => calculateCashDueNow(this.swisherTaxableCashProducts()));
    swisherGratisTotal = computed(() => calculateGratisTotal(this.swisherGratisProducts()));
    swisherTaxableGratisTotal = computed(() => calculateGratisTotal(this.swisherTaxableGratisProducts()));
    swisherExchangeOutTotal = computed(() => calculateExchangeOutTotal(this.swisherExchangeOutProducts()));
    swisherTaxableExchangeOutTotal = computed(() => calculateExchangeOutTotal(this.swisherTaxableExchangeOutProducts()));
    swisherExchangeInTotal = computed(() => calculateExchangeInTotal(this.swisherExchangeInProducts()));
    swisherTaxableExchangeInTotal = computed(() => calculateExchangeInTotal(this.swisherTaxableExchangeInProducts()));
    easCashDueNow = computed(() => calculateCashDueNow(this.easCashProducts()));
    easTaxableCashDueNow = computed(() => calculateCashDueNow(this.easTaxableCashProducts()));
    easGratisTotal = computed(() => calculateGratisTotal(this.easGratisProducts()));
    easTaxableGratisTotal = computed(() => calculateGratisTotal(this.easTaxableGratisProducts()));
    easExchangeOutTotal = computed(() => calculateExchangeOutTotal(this.easExchangeOutProducts()));
    easTaxableExchangeOutTotal = computed(() => calculateExchangeOutTotal(this.easTaxableExchangeOutProducts()));
    easExchangeInTotal = computed(() => calculateExchangeInTotal(this.easExchangeInProducts()));
    easTaxableExchangeInTotal = computed(() => calculateExchangeInTotal(this.easTaxableExchangeInProducts()));

    hasSwisherProducts = computed(() => {
        const cashProducts = this.swisherCashProducts();
        const gratisProducts = this.swisherGratisProducts();
        const exchangeOutProducts = this.swisherExchangeOutProducts();
        const exchangeInProducts = this.swisherExchangeInProducts();
        const hasSwisherProducts =
            cashProducts.length > 0 || gratisProducts.length > 0 || exchangeOutProducts.length > 0 || exchangeInProducts.length > 0;
        return hasSwisherProducts;
        // this.swisherCashProducts().length > 0 || this.swisherGratisProducts().length > 0 || this.swisherExchangeOutProducts().length > 0 || this.swisherExchangeInProducts().length > 0
    });
    hasEasProducts = computed(
        () =>
            this.easCashProducts().length > 0 ||
            this.easGratisProducts().length > 0 ||
            this.easExchangeOutProducts().length > 0 ||
            this.easExchangeInProducts().length > 0
    );
}

const calculateCashDueNow = (cashProducts: CallProductWithProductAndTotal[]) =>
    cashProducts.reduce((sum, product) => {
        const total = product.quantity * (product.price - product.discount);
        return sum + addTaxToTotal(total, product.callProductTax);
    }, 0);
const calculateGratisTotal = (gratisProducts: CallProductWithProductAndTotal[]) =>
    gratisProducts.reduce((sum, product) => {
        const total = product.value * product.quantity;
        return sum + addTaxToTotal(total, product.callProductTax);
    }, 0);

const calculateExchangeOutTotal = (exchangeOutProducts: CallProductWithProductAndTotal[]) =>
    exchangeOutProducts.reduce((sum, product) => {
        const total = product.quantity * product.price;
        return sum + addTaxToTotal(total, product.callProductTax, -1);
    }, 0);

const calculateExchangeInTotal = (exchangeInProducts: CallProductWithProductAndTotal[]) =>
    exchangeInProducts.reduce((sum, product) => {
        const total = product.quantity * product.price;
        return sum + addTaxToTotal(total, product.callProductTax);
    }, 0);

const addTaxToTotal = (total: number, tax: ProductTax | undefined, taxModifier: 1 | -1 = 1) =>
    total + (tax?.statePrepaid ? 0 : tax?.stateTaxAmount ?? 0) * taxModifier + (tax?.countyTaxAmount ?? 0) * taxModifier + (tax?.cityTaxAmount ?? 0) * taxModifier;

const filterSwisher = (callProduct: CallProductWithProductAndTotal) =>
    Helper.equalsIgnoreCase(callProduct.product?.subsidiary, SubsidiaryTypes.swisher) ||
    Helper.equalsIgnoreCase(callProduct.product?.subsidiary, SubsidiaryTypes.rogueholdingsLlc);

const filterEas = (callProduct: CallProductWithProductAndTotal) =>
    Helper.equalsIgnoreCase(callProduct.product?.subsidiary, SubsidiaryTypes.eas);
