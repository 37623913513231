<div #originalEasRetailReceipt *ngIf="hasEasProducts()">
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
    <div>
        <div class="row">
            <div class="col center">
                {{ customer().businessAddress.name }} - Receipt #{{receiptNumber()}}
            </div>
        </div>
        <div *ngIf="stateVaporLicense()" class="row">
            <div class="col center">
                Retail State Vapor License # {{ stateVaporLicense()?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">Retail State OTP License # {{stateOptLicense()?.licenseNumber}}</div>
        </div>
        <div class="row">
            <div class="col center">
                {{ formattedAddress() }}
            </div>
        </div>
        <div *ngIf="customer().businessAddress?.county" class="row">
            <div class="col center">{{ customer().businessAddress?.county }}&nbsp;County</div>
        </div>
        <hr />
        <div *ngIf="easCashProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Cash Sales Item</b>
                </div>
                <div class="col-1">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-2">
                    <b>Discount</b>
                </div>
                <div class="col-2">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let cashProduct of easCashProducts()">
                <div class="col-5">
                    {{ cashProduct.product?.description }}
                </div>
                <div class="col-1">
                    {{ cashProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ (cashProduct.callProductTax?.statePrepaid ? (cashProduct.price - (cashProduct.callProductTax?.stateTaxAmount / cashProduct.quantity)) : cashProduct.price) | currency }}
                </div>
                <div class="col-2">
                    {{ cashProduct.discount | currency }}
                </div>
                <div class="col-2">
                    {{ (cashProduct.callProductTax?.statePrepaid ? cashProduct.total - cashProduct.callProductTax?.stateTaxAmount : cashProduct.total) | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-1">
                    1
                </div>
                <div class="col-2">
                    {{ taxByType(easCashProducts()) | currency }}
                </div>
                <div class="col-2">
                    {{ 0 | currency }}
                </div>
                <div class="col-2">
                    {{ taxByType(easCashProducts()) | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Total: {{ easCashDueNow() | currency }}</b>
                </div>
            </div>
            <div class="row">
                <div class="col larger total-display">
                    <b><u>Cash Due Now: {{ easCashDueNow() | currency }}</u></b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easGratisProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Promotional Goods</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let gratisProduct of easGratisProducts()">
                <div class="col-5">
                    {{ gratisProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ gratisProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ (gratisProduct.callProductTax?.statePrepaid ? (gratisProduct.value - (gratisProduct.callProductTax?.stateTaxAmount / gratisProduct.quantity)) : gratisProduct.value) | currency }}
                </div>
                <div class="col-3">
                    {{ (gratisProduct.callProductTax?.statePrepaid ? gratisProduct.total - gratisProduct.callProductTax?.stateTaxAmount : gratisProduct.total) | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ taxByType(easGratisProducts()) | currency }}
                </div>
                <div class="col-3">
                    {{ taxByType(easGratisProducts()) | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Promotional Goods Total:
                        {{ easGratisTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeOutProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Exchange Out Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let outProduct of easExchangeOutProducts()">
                <div class="col-5">
                    {{ outProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ outProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ (outProduct.callProductTax?.statePrepaid ? (outProduct.price + (outProduct.callProductTax?.stateTaxAmount / outProduct.quantity)) : outProduct.price) | currency }}
                </div>
                <div class="col-3">
                    {{ (outProduct.callProductTax?.statePrepaid ? outProduct.total + outProduct.callProductTax?.stateTaxAmount : outProduct.total) | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ (taxByType(easExchangeOutProducts()) * -1) | currency }}
                </div>
                <div class="col-3">
                    {{ (taxByType(easExchangeOutProducts()) * -1) | currency }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col total-display">
                    <b>Exchange Out Total:
                        {{ easExchangeOutTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeInProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-5">
                    <b>Exchange In Item</b>
                </div>
                <div class="col-2">
                    <b>Qty</b>
                </div>
                <div class="col-2">
                    <b>Price</b>
                </div>
                <div class="col-3">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let inProduct of easExchangeInProducts()">
                <div class="col-5">
                    {{ inProduct.product?.description }}
                </div>
                <div class="col-2">
                    {{ inProduct.quantity }}
                </div>
                <div class="col-2">
                    {{ (inProduct.callProductTax?.statePrepaid ? (inProduct.price - (inProduct.callProductTax?.stateTaxAmount / inProduct.quantity)) : inProduct.price) | currency }}
                </div>
                <div class="col-3">
                    {{ (inProduct.callProductTax?.statePrepaid ? inProduct.total - inProduct.callProductTax?.stateTaxAmount : inProduct.total) | currency }}
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-5">
                    Taxes
                </div>
                <div class="col-2">
                    1
                </div>
                <div class="col-2">
                    {{ taxByType(easExchangeInProducts()) | currency }}
                </div>
                <div class="col-3">
                    {{ taxByType(easExchangeInProducts()) | currency }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Exchange In Total: {{ easExchangeInTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easTaxableProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-4">
                    <b>Taxes Paid</b>
                </div>
                <div class="col-2">
                    <b>State</b>
                </div>
                <div class="col-2">
                    <b>County</b>
                </div>
                <div class="col-2">
                    <b>City</b>
                </div>
                <div class="col-2">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let tax of easTaxableProducts()">
                <div class="col-4">
                    {{ tax.productDescription }}
                </div>
                <div class="col-2">
                    {{ tax.stateTax | currency }}
                </div>
                <div class="col-2">
                    {{ tax.countyTax | currency }}
                </div>
                <div class="col-2">
                    {{ tax.cityTax | currency }}
                </div>
                <div class="col-2">
                    {{ tax.total | currency }}
                </div>
            </div>
            <hr />
            <div class="row margin-right">
                <div class="col-4">
                    <b>Total Tax</b>
                </div>
                <div class="col-2">
                    <b>{{ easStateTaxTotal() | currency}}</b>
                </div>
                <div class="col-2">
                    <b>{{ easCountyTaxTotal() | currency }}</b>
                </div>
                <div class="col-2">
                    <b>{{ easCityTaxTotal() | currency }}</b>
                </div>
                <div class="col-2">
                    <b>{{ easTaxTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
    </div>
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
</div>
