//Angular
import {
    Component,
    EventEmitter, HostBinding,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { debounce, debounceTime, map } from "rxjs/operators";

//Services
import { DatabaseService } from "src/app/services/database.service";

//Models
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import {
    NotificationRequestDto,
    SharedHelper,
    newSequentialId
} from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { DirectionsDialogComponent } from "src/app/dialogs/directions-dialog/directions-dialog.component";
import { DirectionsDialogViewModel } from "src/app/dialogs/directions-dialog/directions-dialog.viewmodel";
import { FinishRouteDialogComponent } from "src/app/dialogs/finish-route-dialog/finish-route-dialog.component";
import { FinishRouteDialogViewModel } from "src/app/dialogs/finish-route-dialog/finish-route-dialog.viewmodel";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { GeocoderService } from "src/app/services/geocoder.service";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { AccountRoutingModeViewmodel } from "./account-routing-mode.viewmodel";

import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { GoogleMap, MapDirectionsService, MapInfoWindow } from "@angular/google-maps";
import { MarkerClustererOptions } from "@googlemaps/markerclustererplus";
import cloneDeep from "lodash-es/cloneDeep";
import { Moment } from "moment";
import { Address } from "src/app/entity-models/address.entity";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { Route } from "src/app/entity-models/route.entity";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { AppStateService } from "src/app/services/app-state.service";
import { RouteConverterService } from "src/app/services/converter-services/route-converter.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { RouteDelineationService } from "src/app/services/delineation-services/route-delineation.service";
import { PingService } from "src/app/services/ping.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { SyncService } from "src/app/services/sync.service";
import { RoutingModeFilters } from "../../account-enums/routing-mode-filters";
import { CustomerMapService } from "../../account-services/customer-map.service";
import { AccountsListViewModel } from "../accounts-list.viewmodel";
@Component({
    selector: "app-accounts-list-routing-mode",
    templateUrl: "./accounts-list-routing-mode.component.html",
    styleUrls: ["./accounts-list-routing-mode.component.scss"]
})
export class AccountsListRoutingModeComponent
    implements OnInit, OnDestroy {

    //Public vars
    mapCenter: google.maps.LatLngLiteral = { lat: 30.35446, lng: -81.64654 };
    mapZoom = 8;

    mapLoading = false;
    markersRebuilding = false;
    latestViewId: string;
    activeSearch = false;

    preventRouteOptimization: boolean = true; //Prevents route optimization except on first load or when manually triggered

    previousInfoWindow: MapInfoWindow = null;
    viewmodel: AccountRoutingModeViewmodel;
    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    finishRouteOverLayRef: SwisherOverlayRef<
        FinishRouteDialogViewModel,
        FinishRouteDialogComponent
    >;
    directionsOverLayRef: SwisherOverlayRef<
        DirectionsDialogViewModel,
        DirectionsDialogComponent
    >;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    momentDateFormat = MY_DATE_FORMATS.display.dateInput;

    private _routingMode = false;
    @Input()
    get routingMode(): boolean {
        return this._routingMode;
    }
    set routingMode(value: boolean) {
        if (value === undefined) return;

        this._routingMode = value;
        if (!value) {
            this.routeManagementService.resetRoute();
            this.viewmodel.resetMarkers();
            return;
        }
        this.setRoutingMode();
    }

    @Input() accountsVm: AccountsListViewModel;

    private _futureRoutes: Route[];
    private futureRoutesSet: boolean = false;
    @Input()
    get futureRoutes(): Route[] {
        return this._futureRoutes;
    }
    set futureRoutes(value: Route[]) {
        this._futureRoutes = value;
        this.viewmodel.futureRouteMap = new Map(value.map((v) => [this.getRouteDateKey(v.date), v]));
        this.futureRoutesSet = true;
    }

    @Output() routingModeTouched = new EventEmitter();
    @Output() routingModeUntouched = new EventEmitter();
    @Output() routingModeToggled = new EventEmitter();

    @Output() mapViewToggled = new EventEmitter<boolean>();

    mapView = false;
    directionsView = false;

    directionsModalOpened = false;
    connectivityModalOpened = false;

    employeeOtherPhone: string;

    private subscriptionEmployee: Subscription;
    employeeSet: boolean;
    private subscriptionIsOnline: Subscription;
    onlineSet: boolean;
    private subscriptionRouteCustomerIds: Subscription;
    private subscriptionSearchRefiners: Subscription;

    initialized = false;

    @ViewChild("viewPort") viewPort: CdkVirtualScrollViewport;
    @ViewChild("map") map: GoogleMap;
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

    @HostBinding("class") class = "d-flex flex-column flex-grow-1";

    readonly routingModeFilters = RoutingModeFilters;

    public constructor(
        public overlayService: OverlayService,
        public router: Router,
        public route: ActivatedRoute,
        private dbService: DatabaseService,
        private zone: NgZone,
        private geocoder: GeocoderService,
        private dataSyncQueueService: DataSyncQueueService,
        private routeManagementService: RouteManagementService,
        private pingService: PingService,
        private snackbar: SnackbarService,
        private appStateService: AppStateService,
        private notificationDelineationService: NotificationDelineationService,
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private employeeDelineationService: EmployeeDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private routeDelineationService: RouteDelineationService,
        public syncService: SyncService,
        private px3RankService: Px3DelineationService,
        directionsService: MapDirectionsService,
    ) {
        this.viewmodel = new AccountRoutingModeViewmodel(
            this.dbService,
            this.geocoder,
            this.routeManagementService,
            directionsService
        );
        this.debouncer.pipe(debounceTime(200)).subscribe(() => this.loadVisibleMarkers());
    }
    public mapMarkerClusterOptions: MarkerClustererOptions = {
        maxZoom: 12
    };
    public mapStyles: google.maps.MapTypeStyle[] = [
        {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
        }
    ];

    public mapDirectionRenderOptions: google.maps.DirectionsRendererOptions = {
        preserveViewport: true,
        suppressMarkers: true
    };

    async ngOnInit(): Promise<void> {
        if (!this.subscriptionIsOnline || this.subscriptionIsOnline.closed) {
            this.subscriptionIsOnline = this.pingService.online.subscribe(
                () => {
                    if (this.employeeDelineationService.getOnlineState()) {
                        if (!this.viewmodel.currentPosition) {
                            Helper.verboseLog("Online and pulling current position.");
                            navigator.geolocation.getCurrentPosition((position) => {
                                const latLng: google.maps.LatLngLiteral = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };
                                this.mapCenter = latLng;
                                this.viewmodel.currentPosition = latLng;
                                this.onlineSet = true;
                            });
                        }
                    } else if (this.routingMode) {
                        if (!this.connectivityModalOpened) {
                            this.connectivityModalOpened = true;

                            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                            data.header = "Warning - Internet Connectivity Lost!";
                            data.message =
                                "You must be online to build a route.  Do you wish to save your current route changes?";
                            data.buttonLeftText = "No, Discard Changes";
                            data.buttonLeftFunction = () => {
                                this.confirmationOverlayRef.close(data);
                            };
                            data.buttonRightText = "Yes, Save Changes";
                            data.buttonRightFunction = () => {
                                data.isConfirmed = true;
                                this.confirmationOverlayRef.close(data);
                            };

                            this.confirmationOverlayRef = this.overlayService.open(
                                ConfirmationDialogComponent,
                                data
                            );

                            this.confirmationOverlayRef.afterClosed$
                                .pipe(
                                    map(async (event) => {
                                        if (event && event.data && event.data.isConfirmed) {
                                            await this.saveRoute({
                                                routeName: this.viewmodel.route.name ?? this.viewmodel.route.date.toLocaleDateString(),
                                                routeDescription: this.viewmodel.route.description ?? ""
                                            });
                                        }
                                        this.routingModeUntouched.emit();
                                        this.routeManagementService.resetRoute();
                                        this.viewmodel.initRoute();
                                        this.viewmodel.routeDate = new Date();

                                        if (this.mapView) {
                                            this.setMapView();
                                        }

                                        this.connectivityModalOpened = false;
                                        this.routingModeToggled.emit();
                                    })
                                )
                                .subscribe();
                        }
                    }
                }
            );
        }

        if (!this.subscriptionEmployee || this.subscriptionEmployee.closed) {
            this.subscriptionEmployee = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    this.viewmodel.employee = employee;
                    if (employee) {
                        this.employeeSet = true;
                        const userResponse = await this.registeredUserDelineationService.getByEmployeeId(employee.id);
                        if (userResponse?.values) {
                            this.employeeOtherPhone = userResponse.values.otherPhone;
                        }
                    }
                }
            );
        }

        if (!this.subscriptionRouteCustomerIds || this.subscriptionRouteCustomerIds.closed) {
            this.subscriptionRouteCustomerIds = this.routeManagementService.observableRouteCustomers.subscribe(
                (markers) => {
                    const oldIds = this.viewmodel.routeCustomerIds.filter(v => !markers.map(v => v.customerId).includes(v));
                    const newMarkers = markers.filter(v => !this.viewmodel.routeCustomerIds.includes(v.customerId));

                    for (const id of oldIds) {
                        const marker = this.viewmodel.foundRouteCustomers.find(v => v.customerId == id);
                        this.viewmodel.removeMarkerFromRoute(marker);
                    }

                    for (const marker of newMarkers) {
                        if (!this.viewmodel.foundRouteCustomers.map(v => v.customerId).includes(marker.customerId)) {
                            this.viewmodel.foundRouteCustomers.push(marker);
                        }
                        this.viewmodel.setCustomerMarkerOnRoute(marker, true);
                    }
                }
            );
        }

        if (!this.subscriptionSearchRefiners || this.subscriptionSearchRefiners.closed) {
            this.subscriptionSearchRefiners = this.accountsVm.observableSearchRefiners.subscribe(
                () => {
                    if (this.mapView) {
                        this.closeInfoWindow();
                    }
                    this.markersRebuilding = true;
                    this.viewmodel.customerMarkers = [];
                    this.loadVisibleMarkers();
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptionIsOnline && !this.subscriptionIsOnline.closed) {
            this.subscriptionIsOnline.unsubscribe();
        }
        if (this.subscriptionEmployee && !this.subscriptionEmployee.closed) {
            this.subscriptionEmployee.unsubscribe();
        }
        if (this.subscriptionRouteCustomerIds && !this.subscriptionRouteCustomerIds.closed) {
            this.subscriptionRouteCustomerIds.unsubscribe();
        }
        if (this.subscriptionSearchRefiners && !this.subscriptionSearchRefiners.closed) {
            this.subscriptionSearchRefiners.unsubscribe();
        }
        this.routeManagementService.resetRoute();
    }

    //Events
    async setRoutingMode(): Promise<void> {
        if (this.futureRoutesSet) {
            if (this.routeManagementService.route?.id) {
                this.viewmodel.route = this.routeManagementService.route;
                this.viewmodel.routeDate = this.routeManagementService.route.date;
                this.setPageEditMode(true);
            } else {
                this.mapView = false;
                this.setTodaysRoute();
            }
            const routeCustomerResponse = await this.customerDelineationService.getByIds(
                this.viewmodel.route.stops.map(v => v.customerId).filter(v => !!v)
            );
            if (routeCustomerResponse) {
                const px3Ranks = new Map((await this.px3RankService.getAll()).map(pr => [pr.id, pr.rank]));
                this.viewmodel.foundRouteCustomers = routeCustomerResponse.values.map(
                    v => CustomerMapService.getCustomerMapMarker(v, px3Ranks.get(v.px3RankId))
                );
            }
            this.viewmodel.buildRouteMarkers();
            this.mapViewToggled.emit(this.mapView);
            void this.viewmodel.validateLatLngsForCurrentRoute();
        }
    }

    //Public methods

    async onDirectionsResultResponse(event: google.maps.DirectionsResult): Promise<void> {
        if (!event) {
            this.snackbar.showWarning("Google Maps API: There was an error sending your directions request.")
            return;
        }
        const order = event.routes[0]?.waypoint_order;
        let shouldOptimize = false;
        for (const i in order) {
            if (order[Number(i) + 1] == undefined) {
                break;
            }
            if (order[i] > order[Number(i) + 1]) {
                shouldOptimize = true;
            }
        }
        if (shouldOptimize && !this.preventRouteOptimization) {
            this.viewmodel.setOptimizedWaypoints(order);
            this.viewmodel.setRouteTimeAndDistance(event.routes[0]?.legs);
            this.preventRouteOptimization = true;
        }
    }

    optimizeWaypoints(): void {
        this.preventRouteOptimization = false;
        this.viewmodel.buildRouteMarkers(true);
    }

    setMapView() {
        this.closeInfoWindow();
        if (!this.directionsView) {
            this.mapView = !this.mapView;
            if (this.mapView) {
                this.loadVisibleMarkers();
            }
        } else {
            this.directionsView = false;
        }
        this.mapViewToggled.emit(this.mapView);
    }

    async setRouteCustomer(customerMarker: CustomerMarker, isOnRoute: boolean): Promise<void> {
        this.closeInfoWindow();
        if (!customerMarker) return;
        this.viewmodel.isRoutePristine = false;
        this.routingModeTouched.emit();
        this.viewmodel.setCustomerMarkerOnRoute(customerMarker, isOnRoute);

        void this.removeRouteIfEmpty();
    }

    async removeRouteStop(stop: RouteStop): Promise<void> {
        if (!stop) return;
        this.viewmodel.isRoutePristine = false;
        this.routingModeTouched.emit();
        this.viewmodel.removeRouteStop(stop, false);

        void this.removeRouteIfEmpty();
    }

    async removeRouteIfEmpty(): Promise<void> {
        if (
            !this.viewmodel.routeCustomerIds.length && (
                this.viewmodel.futureRouteMap.get(this.getRouteDateKey(this.viewmodel.route.date)) ||
                moment(this.viewmodel.route.date).startOf('day').isSameOrAfter(moment().startOf('day'))
            )
        ) {
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Empty Route";
            data.message =
                "The current route has no stops. Would you like to remove this route?";
            data.buttonLeftText = "Cancel";
            data.buttonLeftFunction = () => {
                this.confirmationOverlayRef.close(data);
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                data.isConfirmed = true;
                this.confirmationOverlayRef.close(data);
            };

            this.confirmationOverlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.confirmationOverlayRef.afterClosed$
                .pipe(
                    map(async (event) => {
                        if (
                            event &&
                            event.data &&
                            event.data.isConfirmed
                        ) {
                            await this.routeDelineationService.offlineOnlineDeleteRoute(this.viewmodel.route.id);
                            this.accountsVm.routeTouched = false;
                            this.syncService.forceOutboundSync();
                            this.router.navigate(["/my-day", "route-management"]);
                        }

                    })
                )
                .subscribe();
        }
    }

    setTodaysRoute(): void {
        const today = this.viewmodel.futureRouteMap.get(this.getRouteDateKey(new Date()));
        if (today) {
            this.routeManagementService.route = this.viewmodel.route = cloneDeep(today);
            this.setPageEditMode();
        }
    }

    async finishRoute(): Promise<void> {
        const data: FinishRouteDialogViewModel = new FinishRouteDialogViewModel();

        if (this.viewmodel.employee.managerEmployeeId) {
            const managerResponse = await this.employeeDelineationService.getById(this.viewmodel.employee.managerEmployeeId);

            data.supervisorEmployeeOptions = managerResponse ? [managerResponse.values] : [];
        }

        data.routeName = this.viewmodel.route.name ?? this.viewmodel.route.date.toLocaleDateString();
        data.routeDescription = this.viewmodel.route.description ?? "";
        data.isEdit = !!this.viewmodel.route.id;

        data.onSave = async (
            saveData: FinishRouteDialogViewModel
        ): Promise<void> => {
            // was route on this day already added?
            const routes = await this.dbService.routes
                .where("employeeId")
                .equals(this.viewmodel.employee.id)
                .and(r => r.date.setHours(0, 0, 0, 0) === this.viewmodel.route.date.setHours(0, 0, 0, 0))
                .sortBy("date");

            let existing: Route = null;
            if (routes && routes.length > 0) {
                existing = routes[0];
            }

            if (existing) {
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Warning - Existing Route Found!";
                data.message =
                    "A route for you already exists for this day. Would you like to replace it with your current route?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (
                                event &&
                                event.data &&
                                event.data.isConfirmed
                            ) {
                                await this.dbService.routes.delete(existing.id);
                                await this.onRouteSave(saveData);
                            }
                        })
                    )
                    .subscribe();
            } else {
                await this.onRouteSave(saveData);
            }
        };

        this.finishRouteOverLayRef = this.overlayService.open(
            FinishRouteDialogComponent,
            data
        );
    }

    async onRouteSave(saveData: FinishRouteDialogViewModel) {
        console.log('Saving Route')
        this.routingModeUntouched.emit();

        // save the thing
        this.saveRoute(saveData);
        this.syncService.forceOutboundSync();

        // notification
        await this.viewmodel.validateLatLngsForCurrentRoute();

        const routeMessage = RouteConverterService.formatRouteMessage(
            this.viewmodel.employee,
            this.viewmodel.route,
            saveData.supervisorTextMessage,
            SharedHelper.buildGoogleMapsRouteUrl(
                this.viewmodel.route.stops.map((s) => {
                    if (s.address.latitude && s.address.longitude) {
                        return `${s.address.latitude},${s.address.longitude}`;
                    } else {
                        const found = this.viewmodel.foundLatLngs.find((ll) => s.address.id
                            ? ll.title === s.address.id
                            : ll.addressText == s.address.address1 && ll.cityStateZip == s.address.city);
                        if (found) {
                            return `${found.address.latitude},${found.address.longitude}`;
                        }
                    }
                })
            )
        );

        // employee message
        if (saveData.textRoute || saveData.emailRoute) {
            const request = new NotificationRequestDto();
            request.id = newSequentialId();
            request.sendSms = saveData.textRoute;
            request.sendEmail = saveData.emailRoute;
            request.employeeId = this.viewmodel.employee.id;
            request.recipientEmployeeId = this.viewmodel.employee.id;
            request.subject = routeMessage.subject;
            request.message = routeMessage.message;
            request.textMessage = routeMessage.textMessage;
            request.recipientOtherPhone = this.employeeOtherPhone;

            await this.notificationDelineationService.send(request);
        }

        // supervisor message
        if (saveData.supervisorEmployeeId && (saveData.supervisorTextRoute || saveData.supervisorEmailRoute)) {
            const supervisorRequest = new NotificationRequestDto();
            supervisorRequest.id = newSequentialId();
            supervisorRequest.sendSms = saveData.textRoute;
            supervisorRequest.sendEmail = saveData.emailRoute;
            supervisorRequest.employeeId = this.viewmodel.employee.id;
            supervisorRequest.recipientEmployeeId = saveData.supervisorEmployeeId;
            supervisorRequest.subject = routeMessage.subject;
            supervisorRequest.message = routeMessage.message;
            supervisorRequest.textMessage = routeMessage.textMessage;

            await this.notificationDelineationService.send(supervisorRequest);
        }

        this.closeInfoWindow();
        if (saveData.isEdit) {
            void this.router.navigate(["/my-day", "route-management"]);
        } else {
            this.routeManagementService.resetRoute();
            this.viewmodel.initRoute();
            this.viewmodel.routeDate = new Date();
            this.mapView = false;
            this.directionsView = false;
            this.accountsVm.onToggleRoutingMode(false);
            this.mapViewToggled.emit(this.mapView);
        }
        console.log('Sync Triggered by Saved Route.');
        this.syncService.forceSync();
    }

    async onSetDrivingDirections(): Promise<void> {
        this.preventRouteOptimization = false;
        this.directionsModalOpened = true;
        this.viewmodel.isRoutePristine = false;
        this.routingModeTouched.emit();
        await this.viewmodel.setDirectionsFlags();
        await this.getPrevAddresses(this.viewmodel.employee.id);

        this.setDrivingDirections();
        this.directionsModalOpened = false;
    }

    setDrivingDirections(): void {
        const route = this.viewmodel.route;
        if (route.stops.length === 0) return;

        const firstAddress = route.stops[0].address;
        const lastAddress = route.stops[route.stops.length - 1].address;

        const data: DirectionsDialogViewModel = new DirectionsDialogViewModel();
        data.storageLocation = !!this.viewmodel.employee.shipAddress1;
        data.startFirstStopLocation = { ...firstAddress };
        data.endLastStopLocation = { ...lastAddress };
        data.startAtCurrentLocation = this.viewmodel.routeStartAtCurrent;
        data.startAtStorageLocation = this.viewmodel.routeStartAtStorage;
        data.startPrevLocations = this.viewmodel.routePrevStartAddresses;
        data.endAtCurrentLocation = this.viewmodel.routeEndAtCurrent;
        data.endAtStorageLocation = this.viewmodel.routeEndAtStorage;
        data.endAtStartingLocation = this.viewmodel.routeEndAtStart;
        data.endPrevLocations = this.viewmodel.routePrevEndAddresses;
        data.geocoder = this.geocoder;

        data.onSave = async (
            saveData: DirectionsDialogViewModel
        ): Promise<void> => {
            this.closeInfoWindow();
            await this.viewmodel.unsetCustomStartEndLocations();

            if (
                !saveData.startAtCurrentLocation &&
                !saveData.startAtStorageLocation
            ) {
                await this.viewmodel.setRouteStartAtCustom(
                    saveData.startLocation
                );
            }
            await this.viewmodel.setRouteStartAtCurrent(
                saveData.startAtCurrentLocation
            );
            await this.viewmodel.setRouteStartAtStorage(
                saveData.startAtStorageLocation
            );

            if (
                !saveData.endAtCurrentLocation &&
                !saveData.endAtStorageLocation &&
                !saveData.endAtStartingLocation
            ) {
                await this.viewmodel.setRouteEndAtCustom(
                    saveData.endLocation
                );
            }
            await this.viewmodel.setRouteEndAtCurrent(
                saveData.endAtCurrentLocation
            );
            await this.viewmodel.setRouteEndAtStorage(
                saveData.endAtStorageLocation
            );
            await this.viewmodel.setRouteEndAtStart(
                saveData.endAtStartingLocation
            );

            await this.updateRoute();
        };

        this.directionsOverLayRef = this.overlayService.open(
            DirectionsDialogComponent,
            data
        );
    }

    async updateRoute(): Promise<void> {
        this.directionsView = true;
        this.mapView = true;
        this.mapViewToggled.emit(this.mapView);
    }

    closeInfoWindow(): void {
        if (this.previousInfoWindow !== null) {
            try {
                this.zone.runOutsideAngular(() => {
                    void this.previousInfoWindow.close();
                    this.previousInfoWindow = null;
                });
            } catch (e) {
                this.snackbar.showError(e);
            }
        }
    }

    openInfoWindow(
        event: google.maps.MapMouseEvent,
        marker: CustomerMarker
    ): void {
        try {
            this.closeInfoWindow();
            this.previousInfoWindow = this.infoWindow;
            this.viewmodel.infoMarker = marker;
            this.zone.runOutsideAngular(() => {
                this.infoWindow.position = event.latLng;
                void this.infoWindow.open();
            });
        } catch (e) {
            this.snackbar.showError(e);
        }
    }

    setPageEditMode(navigatedTo?: boolean, newRoute?: boolean): void {
        if (navigatedTo) {
            this.mapView = true;
        }
        this.mapViewToggled.emit(this.mapView);
        this.viewmodel.finishButtonText = newRoute ? "Finish/Download" : "Update/Download";
    }

    changeRouteDate(event: Date): void {
        const selectedRouteDate = new Date(event);
        if (Helper.isValidMomentDate(moment(selectedRouteDate), this.dateFormat)) {
            const futureRoute = this.viewmodel.futureRouteMap.get(this.getRouteDateKey(selectedRouteDate));
            if (futureRoute) {
                if (this.viewmodel.isRoutePristine) {
                    this.routeManagementService.route = this.viewmodel.route = cloneDeep(futureRoute);
                    this.setPageEditMode();

                    this.viewmodel.routeDate = this.viewmodel.route.date;
                    this.viewmodel.buildRouteMarkers();
                } else {
                    const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                    data.header = "Warning - Unsaved Changes!";
                    data.message =
                        "A route already exists on this day. Would you like to edit the existing route? Changes to your current day's route will be lost.";
                    data.buttonLeftText = "Cancel";
                    data.buttonLeftFunction = () => {
                        this.confirmationOverlayRef.close(data);
                    };
                    data.buttonRightText = "Yes";
                    data.buttonRightFunction = () => {
                        data.isConfirmed = true;
                        this.confirmationOverlayRef.close(data);
                    };

                    this.confirmationOverlayRef = this.overlayService.open(
                        ConfirmationDialogComponent,
                        data
                    );

                    this.confirmationOverlayRef.afterClosed$
                        .pipe(
                            map(async (event) => {
                                if (
                                    event &&
                                    event.data &&
                                    event.data.isConfirmed
                                ) {
                                    this.routeManagementService.resetRoute();
                                    this.viewmodel.initRoute();
                                    this.viewmodel.route = futureRoute;
                                    this.setPageEditMode();
                                }
                                this.viewmodel.routeDate = this.viewmodel.route.date;
                                this.viewmodel.buildRouteMarkers();
                            })
                        )
                        .subscribe();
                }
                return;
            }
            if (this.viewmodel.isRoutePristine) {
                this.routeManagementService.resetRoute();
                this.viewmodel.initRoute();
                this.viewmodel.routeDate = this.viewmodel.route.date = selectedRouteDate;
                this.setPageEditMode(false, true);
                this.viewmodel.buildRouteMarkers();
            } else {
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Warning - Unsaved Changes!";
                data.message =
                    "Would you like to stop editing the existing route and start a new one? Your current route changes will be lost.";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (
                                event &&
                                event.data &&
                                event.data.isConfirmed
                            ) {
                                this.routeManagementService.resetRoute();
                                this.viewmodel.initRoute();
                                this.viewmodel.route.date = selectedRouteDate;
                                this.setPageEditMode(false, true);
                            }
                            this.viewmodel.routeDate = this.viewmodel.route.date;
                            this.viewmodel.buildRouteMarkers();
                        })
                    )
                    .subscribe();
            }
        }
    }

    async getPrevAddresses(employeeId: string): Promise<void> {
        const prevAddresses = await this.dbService.routes
            .where("employeeId").equals(employeeId)
            .reverse()
            .sortBy("rowversion");

        if (prevAddresses) {
            const startAddresses = new Array<Address>();
            for (let prevAddress of prevAddresses) {
                if (startAddresses.length === 5) break;
                const address = prevAddress.stops[0]?.address;
                if (address && !startAddresses.find((a) => a === address)) {
                    startAddresses.push(address);
                }
            }
            const endAddresses = new Array<Address>();
            for (let prevAddress of prevAddresses) {
                if (endAddresses.length === 5) break;
                const address =
                    prevAddress.stops[prevAddress.stops.length - 1]?.address;
                if (address && !endAddresses.find((a) => a === address)) {
                    endAddresses.push(address);
                }
            }

            this.viewmodel.routePrevStartAddresses = startAddresses;
            this.viewmodel.routePrevEndAddresses = endAddresses;
        }
    }

    filterMarkersByType(type?: RoutingModeFilters) {
        this.markersRebuilding = true;
        if (!type) {
            this.viewmodel.typeFilters = new Array<RoutingModeFilters>();
            this.viewmodel.filterCustomerMarkers();
        } else {
            if (this.viewmodel.typeFilters.includes(type)) {
                this.viewmodel.typeFilters.splice(this.viewmodel.typeFilters.indexOf(type), 1);
            } else {
                this.viewmodel.typeFilters.push(type);
            }
        }
        this.viewmodel.filterCustomerMarkers();
        this.previousInfoWindow = null;
        setTimeout(() => {
            this.markersRebuilding = false;
        }, 0);
    }

    dateClass = (cellDate: Moment, view: string): string => {
        if (Helper.equalsIgnoreCase(view, "month")) {
            let result = "";

            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const present = now.getTime();
            const selectedDate = cellDate.toDate().getTime();
            if (selectedDate >= present) {
                if (
                    !!this.viewmodel.futureRouteMap.get(cellDate.toDate().setHours(0, 0, 0, 0).toString())
                ) {
                    result = "material-datepicker-info";
                } else {
                    result = null;
                }
            }
            return result;
        }
    };

    private debouncer = new Subject<void>();

    setMapBounds(bounds: google.maps.LatLngBounds): void {
        if (!bounds) return;
        this.viewmodel.mapBoundsLower = {
            lat: bounds.getSouthWest().lat(),
            lng: bounds.getSouthWest().lng()
        };

        this.viewmodel.mapBoundsUpper = {
            lat: bounds.getNorthEast().lat(),
            lng: bounds.getNorthEast().lng()
        };
        this.debouncer.next();
        // this.loadVisibleMarkers();
    }

    async loadVisibleMarkers(): Promise<void> {
        if (this.directionsView || this.latestViewId) return;
        this.mapLoading = true;

        if (!this.viewmodel.customerMarkers?.length) {
            this.latestViewId = newSequentialId();
            const markersResponse = await this.customerDelineationService.getMarkersByFilters(
                this.latestViewId,
                this.accountsVm.refinerService.refiners.slice()
            );

            this.latestViewId = null;
            if (!markersResponse) {
                return;
            };
            markersResponse.values.forEach(v => {
                const onRoute = this.futureRoutes.find(r => r.stops.map(s => s.customerId).includes(v.customerId))
                if (onRoute) {
                    v.routed = true;
                    v.icon.url = "assets/img/store-types/routed-store-icon.png";
                }
            })
            this.routeManagementService.customerMarkers = this.viewmodel.customerMarkers = markersResponse.values;
        }

        this.viewmodel.buildRouteMarkers(false, true);
        this.viewmodel.updateNonRouteCustomerMarkers();

        setTimeout(() => {
            this.markersRebuilding = false;
            this.mapLoading = false;
        }, 0);
    }

    resetOnZoom(): void {
        this.closeInfoWindow();
    }

    private getRouteDateKey(date: Date) {
        if (!date) return;
        return date.setHours(0, 0, 0, 0).toString();
    }

    private async saveRoute(saveData: { routeName: string, routeDescription: string }): Promise<void> {
        this.viewmodel.route.id ??= newSequentialId();
        this.viewmodel.route.employeeId = this.viewmodel.employee.id;
        this.viewmodel.route.employeeZrt = this.viewmodel.employee.zrt;
        this.viewmodel.route.name = saveData.routeName;
        this.viewmodel.route.description = saveData.routeDescription;

        this.viewmodel.route.modifiedUserId = this.viewmodel.employee.id;
        this.viewmodel.route.modifiedUserZrt = this.viewmodel.employee.zrt;
        this.viewmodel.route.modifiedUserFullName = this.viewmodel.employee.fullName;
        this.viewmodel.route.modifiedUtcDateTime = new Date();

        await this.routeDelineationService.saveRoute(this.viewmodel.route);
    }

    // End Routing mode specific.
    public get inverseOfTranslation(): string {
        if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewPort["_renderedContentOffset"];
        return `-${offset}px`;
    }
}
