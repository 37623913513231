//Angular
import { Component, OnInit, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

//Services
import { CallService } from "../../call-services/call.service";

//Models
import { CallCashProductViewModel } from "../../call-viewmodels/call-cash-product.viewmodel";
import { CallCashProduct } from "src/app/entity-models/call-cash-product.entity";
import { CallOrderProductViewModel } from "../../call-viewmodels/call-order-product.viewmodel";
import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { CallGratisProduct } from "src/app/entity-models/call-gratis-product.entity";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { Helper } from "src/app/helpers/helper";
import { OverlayService } from "src/app/services/overlay.service";
import { DistributionDialogComponent } from "src/app/dialogs/distribution-dialog/distribution-dialog.component";
import { CallDistributionViewModel } from "../../call-viewmodels/call-distribution.viewmodel";
import { OrderDateOptionsDialogComponent } from "src/app/dialogs/order-date-options-dialog/order-date-options-dialog.component";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { DistributionActionItems } from "../stepper-call-enums/distribution-action-items";
import { CustomerStateService } from "../../../account-services/customer-state.service";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { CallTypes, newSequentialId } from "shield.shared";
import { ErrorLevel } from "src/app/accounts/account-enums/error-level";
import { Customer } from "src/app/entity-models/customer.entity";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { TaxCalculatorComponent } from "../tax-calculator/tax-calculator.component";
import { TaxCalculatorViewModel } from "../tax-calculator/tax-calculator.viewmodel";
import { CallGratisProductViewModel } from "../../call-viewmodels/call-gratis-product.viewmodel";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { WholesalerGroupMemberDelineationService } from "src/app/services/delineation-services/wholesaler-group-member-delineation.service";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";
import { TaxRateDelineationService } from "src/app/services/delineation-services/tax-rate-delineation.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AppStateService } from "src/app/services/app-state.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";

@UntilDestroy()
@Component({
    selector: "app-sales-gratis",
    templateUrl: "./sales-gratis.component.html",
    styleUrls: ["./sales-gratis.component.scss"]
})
export class SalesGratisComponent implements OnInit {
    //Private vars
    private selectedIndexSubscription: Subscription;
    private callSubscription: Subscription;
    private customerSubscription: Subscription;
    private productSubscription: Subscription;
    //Public vars
    readonly distributionGridModeCash: DistributionActionItems =
        DistributionActionItems.cash;
    readonly distributionGridModeOrder: DistributionActionItems =
        DistributionActionItems.order;
    generalDiscount = false;
    isOrderMode = false;
    mode: DistributionActionItems;
    orderProduct: CallOrderProductViewModel[] = [];
    taxCalculatorOverlayRef: SwisherOverlayRef<
        TaxCalculatorViewModel,
        TaxCalculatorComponent
    >;
    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    maxNumber = 1000000;
    maxQuantityNumber = 500;
    maxUnitNumber = 500;
    maxPriceNumber = 500;
    validationErrorMessages: string[] = [];
    isFinalRetailReceiptPrinted = false;
    isFinalWholesaleReceiptPrinted = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    customer: Customer;
    call: RetailCall;
    employee: Employee;
    isGenericWholesalerStoreType = true;
    //https://cuppalabs.github.io/components/multiselectDropdown/
    wholeSalerDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Wholesaler",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        autoPosition: false,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    };
    isTaxAvailable: boolean;


    public constructor(
        public stepperCallApplicationService: StepperCallApplicationService,
        public callService: CallService,
        private overlayService: OverlayService,
        private productDelineationService: ProductDelineationService,
        private customerStateService: CustomerStateService,
        private callValidationService: CallValidationService,
        private pleasewaitService: PleaseWaitService,
        private wholesalerGroupMemberDelineationService: WholesalerGroupMemberDelineationService,
        private stateDelineationService: StateDelineationService,
        private taxRateDelineationService: TaxRateDelineationService,
        private appStateService: AppStateService,
        private swisherOverlayRef: SwisherOverlayRef<CallOrderProductViewModel, OrderDateOptionsDialogComponent>
    ) {}

    ngOnInit(): void {

        this.appStateService.currentEmployee.pipe(untilDestroyed(this)).subscribe((employee) => {
            this.employee = employee;
        });

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerStateService.observableCustomer.pipe(untilDestroyed(this)).subscribe(
                async (customer) => {
                    this.customer = customer;
                    this.isGenericWholesalerStoreType = Helper.getCustomerGenericType(this.customer) === CustomerGenericTypes.wholesale;
                    this.isTaxAvailable = this.customerStateService.isTaxAvailable()
                }
            );
        }

        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex
            .pipe(untilDestroyed(this)).subscribe(
                async (selectedIndex) => {
                    if (
                        !this.callSubscription ||
                        this.callSubscription.closed
                    ) {
                        this.callSubscription = this.callService.observableCall.subscribe(
                            (call) => {
                                if (call) {
                                    if (
                                        call?.callType === CallTypes.retail ||
                                        call?.callType === CallTypes.rmWholesale
                                    ) {
                                        this.call = call;
                                        this.isFinalRetailReceiptPrinted =
                                            call.isFinalRetailReceiptPrinted || call.isEmailFinalRetailReceipt;
                                        this.isFinalWholesaleReceiptPrinted =
                                            call.isFinalWholesaleReceiptPrinted || call.isEmailFinalWholesaleReceipt;
                                        this.runValidation();
                                    }
                                } else {
                                    this.isFinalWholesaleReceiptPrinted = false;
                                    this.isFinalWholesaleReceiptPrinted = false;
                                    this.generalDiscount = false;
                                    this.isOrderMode = false;
                                    this.orderProduct = new Array<CallOrderProductViewModel>();
                                    this.validationErrorMessages = new Array<string>();
                                }
                            }
                        );
                    }

                    if (
                        ((this.callService.call?.callType ===
                            CallTypes.retail &&
                            selectedIndex === RetailStepperStep.salesGratis) ||
                            (this.callService.call?.callType ===
                                CallTypes.rmWholesale &&
                                selectedIndex ===
                                    RmWholesaleStepperStep.salesGratis)) &&
                        (this.stepperCallApplicationService
                            .shouldBuildProducts ||
                            this.stepperCallApplicationService
                                .distributionOrderProducthasBeenUpdated) &&
                        this.callService.call
                    ) {
                        this.stepperCallApplicationService.shouldBuildProducts = false;
                        await this.buildObjects();

                        if (
                            this.callService.call?.callType ===
                                CallTypes.retail ||
                            this.callService.call?.callType ===
                                CallTypes.rmWholesale
                        ) {
                            this.generalDiscount = this.callService.call
                                ?.generalDiscount
                                ? true
                                : false;
                        }
                    }
                }
            );
        }
    }
    
    onProductInputChange() {
        this.callService.saveCallAndNotify();
    }

    //Events
    async onOpenTaxCalculator(): Promise<void> {
        const data: TaxCalculatorViewModel = new TaxCalculatorViewModel(
            this.callService,
            this.productDelineationService,
            this.customerStateService,
            this.stateDelineationService,
            this.taxRateDelineationService
        );
        data.headerLeftText = "Tax Calculator";
        data.buttonLeftFunction = () => this.onClose();

        await data.buildTaxCalculatorRecordViewModels();

        await this.buildCashProductViewModel();
        await this.buildGratisProductViewModel();

        this.taxCalculatorOverlayRef = this.overlayService.open(
            TaxCalculatorComponent,
            data,
            true
        );

        if (
            this.callService.call?.callType === CallTypes.retail ||
            this.callService.call?.callType === CallTypes.rmWholesale
        ) {
            this.callService.call.shouldVisitTax = false;
        }
        await this.callService.saveCallAndNotify();

        this.taxCalculatorOverlayRef.afterClosed$.subscribe(async () => {
            this.runValidation();
            await this.callService.saveCallAndNotify();
        });
    }

    onClose(): void {
        this.taxCalculatorOverlayRef.close(this.taxCalculatorOverlayRef.data);
    }

    async onWholsalerSelectionChange(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        this.pleasewaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        if (orderProduct) {
            orderProduct.uin = null;
        }

        if (orderProduct?.wholesaler?.length) {

            if (orderProduct.selectedProject) {
                //pull from project products
                const projectProduct = orderProduct.selectedProject.projectProducts.find((pp) => pp.productId === orderProduct.product.id
                    && pp.wholesalerId === orderProduct.wholesaler[0].id);
                const wholesalerGroupMemberResponse = await this.productDelineationService.getProductViewmodelsForWholesalerProjectProducts([projectProduct]);
                if (wholesalerGroupMemberResponse?.values) {
                    orderProduct.uin = wholesalerGroupMemberResponse.values[0]?.uin;
                }

            } else {
                const wholesalerProductResponse = await this.productDelineationService.getWholesalerProducts(
                    orderProduct.wholesaler[0].wholesaler
                );
                if (!wholesalerProductResponse) {
                    this.shouldWait$.next(false);
                    return;
                } else {
                    const vm = wholesalerProductResponse.values.find((vm) => vm.id === orderProduct.product.id);
                    if (vm) {
                        orderProduct.uin = vm.uin;
                        orderProduct.dateAvailable = vm.dateAvailable ?? new Date();
                        orderProduct.orderDates = [{ orderDate: vm.dateAvailable ?? new Date(), quantity: orderProduct.quantity }];
                    }
                }
            }
        }
        await this.saveOrderProduct(orderProduct);
        this.stepperCallApplicationService.salesAndGratisWholesaleHasBeenUpdated = true;

        // This is needed to let the distribution grid to know it needs to build its viewmodels
        this.stepperCallApplicationService.shouldBuildProducts = true;
        this.runValidation();

        this.shouldWait$.next(false);
    }

    wholesalerDeselect(orderProduct: CallOrderProductViewModel, event: any): void {
        // Prevent the deselect
        if (event) {
            orderProduct.wholesaler = [event];
        }
    }

    //Public methods
    addCashProduct(): void {
        this.isOrderMode = false;
        this.mode = DistributionActionItems.cash;
        this.selectProductDialog();
    }

    addGratisProduct(): void {
        this.isOrderMode = false;
        this.mode = DistributionActionItems.gratis;
        this.selectProductDialog();
    }

    addOrderProduct(): void {
        this.isOrderMode = false;
        this.mode = DistributionActionItems.order;
        this.selectProductDialog();
    }

    async applyGeneralDiscount(): Promise<void> {
        if (
            this.callService.call?.callType === CallTypes.retail ||
            this.callService.call?.callType === CallTypes.rmWholesale
        ) {
            this.callService.call.generalDiscount = !this.generalDiscount;
        }
        await this.callService.saveCallAndNotify();
    }

    async buildCashProductViewModel(): Promise<void> {
        //build view model from model
        await this.stepperCallApplicationService.buildCashProductViewModel();
    }

    async buildGratisProductViewModel(): Promise<void> {
        await this.stepperCallApplicationService.buildGratisProductViewModel();
    }

    async buildOrderProductViewModel(): Promise<void> {
        await this.stepperCallApplicationService.buildOrderProductViewModel();
    }

    async buildObjects(): Promise<void> {
        this.productSubscription = this.productDelineationService.observableActiveProducts.subscribe(
            async (products) => {
                if (products?.size) {
                    this.pleasewaitService.showProgressSpinnerUntilLoaded(
                        this.shouldWait$
                    );

                    if (this.productSubscription) {
                        this.productSubscription.unsubscribe();
                    }
                    await this.buildCashProductViewModel();
                    await this.buildOrderProductViewModel();
                    await this.buildGratisProductViewModel();
                    this.calculateTotals();
                    this.stepperCallApplicationService.distributionOrderProducthasBeenUpdated = false;

                    this.shouldWait$.next(false);
                }
            }
        );
    }

    

    calculateTotals(): void {
        this.stepperCallApplicationService.calculateTotals();
        this.runValidation();
    }

    async copyCashProduct(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        const startingIndex = this.stepperCallApplicationService.callCashProductViewModels.indexOf(
            cashProduct
        );
        let newProduct: CallCashProductViewModel;
        if (
            startingIndex !== -1 &&
            this.stepperCallApplicationService.callCashProductViewModels
                .length > startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.stepperCallApplicationService.callCashProductViewModels
                        .length && !newProduct;
                i++
            ) {
                if (
                    this.stepperCallApplicationService
                        .callCashProductViewModels[i].isPristine
                ) {
                    newProduct = cashProduct.copyTo(
                        this.stepperCallApplicationService
                            .callCashProductViewModels[i]
                    );
                    this.stepperCallApplicationService.callCashProductViewModels.splice(
                        i,
                        1,
                        newProduct
                    );
                    await this.setTaxValidation();
                }
            }
        }

        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            await this.saveCashProduct(newProduct);
        }
    }

    copyOrderProduct(orderProduct: CallOrderProductViewModel): void {
        const startingIndex = this.stepperCallApplicationService.callOrderProductViewModels.indexOf(
            orderProduct
        );
        let newProduct: CallOrderProductViewModel;
        if (
            startingIndex !== -1 &&
            this.stepperCallApplicationService.callOrderProductViewModels
                .length > startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.stepperCallApplicationService
                        .callOrderProductViewModels.length && !newProduct;
                i++
            ) {
                if (
                    this.stepperCallApplicationService
                        .callOrderProductViewModels[i].isPristine
                ) {
                    newProduct = orderProduct.copyTo(
                        this.stepperCallApplicationService
                            .callOrderProductViewModels[i]
                    );
                    this.stepperCallApplicationService.callOrderProductViewModels.splice(
                        i,
                        1,
                        newProduct
                    );
                }
            }
        }
        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            void this.saveOrderProduct(newProduct);
        }
    }

    async copyGratisProduct(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        const startingIndex = this.stepperCallApplicationService.callGratisProductViewModels.indexOf(
            gratisProduct
        );
        let newProduct: CallGratisProductViewModel;
        if (
            startingIndex !== -1 &&
            this.stepperCallApplicationService.callGratisProductViewModels
                .length > startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.stepperCallApplicationService
                        .callGratisProductViewModels.length && !newProduct;
                i++
            ) {
                if (
                    this.stepperCallApplicationService
                        .callGratisProductViewModels[i].isPristine
                ) {
                    newProduct = gratisProduct.copyTo(
                        this.stepperCallApplicationService
                            .callGratisProductViewModels[i]
                    );
                    this.stepperCallApplicationService.callGratisProductViewModels.splice(
                        i,
                        1,
                        newProduct
                    );

                    await this.setTaxValidation();
                }
            }
        }

        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            await this.saveGratisProduct(newProduct);
        }
    }

    async duplicateCashProduct(
        cashProduct: CallCashProductViewModel,
        fullCopy: boolean
    ): Promise<void> {
        if (
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.cashProducts &&
            this.callService.call.cashProducts.length > 0
        ) {
            const newCashProduct: CallCashProduct = new CallCashProduct();
            newCashProduct.id = newSequentialId();

            if (fullCopy) {
                newCashProduct.discount = cashProduct.discount;
                newCashProduct.price = cashProduct.price;
                newCashProduct.productId = cashProduct.product.id;
                newCashProduct.quantity = cashProduct.quantity;
                newCashProduct.units = cashProduct.units;
            } else {
                newCashProduct.discount = 0;
                newCashProduct.price = 0;
                newCashProduct.productId = cashProduct.product.id;
                newCashProduct.quantity = 1;
                newCashProduct.units = 1;
            }

            this.callService.call.cashProducts.push(newCashProduct);
            await this.callService.saveCallAndNotify();
            await this.buildCashProductViewModel();
            this.calculateTotals();
        }
    }

    async duplicateGratisProduct(
        gratisProduct: CallGratisProductViewModel,
        fullCopy: boolean
    ): Promise<void> {
        if (
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.gratisProducts &&
            this.callService.call.gratisProducts.length > 0
        ) {
            const newGratisProduct: CallGratisProduct = new CallGratisProduct();
            newGratisProduct.id = newSequentialId();

            if (fullCopy) {
                newGratisProduct.value = gratisProduct.value;
                newGratisProduct.productId = gratisProduct.product.id;
                newGratisProduct.quantity = gratisProduct.quantity;
                newGratisProduct.units = gratisProduct.units;
            } else {
                newGratisProduct.value = 0;
                newGratisProduct.productId = gratisProduct.product.id;
                newGratisProduct.quantity = 1;
                newGratisProduct.units = 1;
            }

            this.callService.call.gratisProducts.push(newGratisProduct);
            await this.callService.saveCallAndNotify();
            this.buildGratisProductViewModel();
            this.calculateTotals();
        }
    }

    async duplicateOrderProduct(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.orderProducts &&
            this.callService.call.orderProducts.length > 0
        ) {
            const newOrderProduct: CallOrderProduct = new CallOrderProduct();
            newOrderProduct.id = newSequentialId();

            newOrderProduct.uin = "0";
            newOrderProduct.wholesalerCustomerId = null;
            newOrderProduct.productId = orderProduct.product.id;
            newOrderProduct.quantity = orderProduct.quantity;
            newOrderProduct.units = orderProduct.units;

            this.callService.call.orderProducts.push(newOrderProduct);
            await this.callService.saveCallAndNotify();
            this.buildOrderProductViewModel();
        }
    }

    async decrementCashProductQuantity(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        if (cashProduct.quantity > 1) {
            cashProduct.oldQuantity = cashProduct.quantity;
            cashProduct.quantity--;
            this.saveCashProduct(cashProduct);
            this.calculateTotals();

            await this.setTaxValidation();
        }
    }

    async decrementGratisProductQuantity(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (gratisProduct.quantity > 1) {
            gratisProduct.oldQuantity = gratisProduct.quantity;
            gratisProduct.quantity--;
            await this.saveGratisProduct(gratisProduct);
            this.calculateTotals();

            await this.setTaxValidation();
        }
    }

    compareCustomerOptions(a: Customer, b: Customer): boolean {
        return a?.id === b?.id;
    }

    async setCashProductUPC(
        cashProduct: CallCashProductViewModel,
        upc: string
    ): Promise<void> {
        cashProduct.upc = upc;
        cashProduct.units = cashProduct.unitsOfMeasure.find(uom => uom.upc === upc)?.noOfEaches;
        await this.saveCashProduct(cashProduct);
        this.calculateTotals();
        await this.setTaxValidation();
    }

    async setOrderProductUPC(
        orderProduct: CallOrderProductViewModel,
        upc: string
    ): Promise<void> {
        orderProduct.upc = upc;
        orderProduct.units = orderProduct.unitsOfMeasure.find(uom => uom.upc === upc)?.noOfEaches;
        await this.saveOrderProduct(orderProduct);
        this.calculateTotals();
        await this.setTaxValidation();
    }

    async setGratisProductUPC(
        gratisProduct: CallGratisProductViewModel,
        upc: string
    ): Promise<void> {
        gratisProduct.upc = upc;
        gratisProduct.units = gratisProduct.unitsOfMeasure.find(uom => uom.upc === upc)?.noOfEaches;
        await this.saveGratisProduct(gratisProduct);
        this.calculateTotals();
        await this.setTaxValidation();
    }

    decrementOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): void {
        if (orderProduct.quantity > 1) {
            orderProduct.quantity--;
            orderProduct.oldQuantity = orderProduct.quantity;
            this.saveOrderProduct(orderProduct);
        }
    }

    async incrementCashProductQuantity(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        if (cashProduct.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(cashProduct);
        } else if (cashProduct.quantity < this.maxQuantityNumber) {
            cashProduct.quantity++;
            cashProduct.oldQuantity = cashProduct.quantity;
            await this.saveCashProduct(cashProduct);
            this.calculateTotals();
        }

        await this.setTaxValidation();
    }

    async incrementGratisProductQuantity(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (gratisProduct.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(gratisProduct);
        } else if (gratisProduct.quantity < this.maxQuantityNumber) {
            gratisProduct.quantity++;
            gratisProduct.oldQuantity = gratisProduct.quantity;
            await this.saveGratisProduct(gratisProduct);
            this.calculateTotals();
        }

        await this.setTaxValidation();
    }

    async incrementOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {

        if (orderProduct.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(orderProduct);
        } else if (orderProduct.quantity < this.maxQuantityNumber) {
            orderProduct.quantity++;
            orderProduct.oldQuantity = orderProduct.quantity;
            await this.saveOrderProduct(orderProduct);
        }
    }

    async removeCashProduct(
        cashRetailProduct: CallCashProductViewModel
    ): Promise<void> {
        if (
            this.callService &&
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.cashProducts &&
            this.callService.call.cashProducts.length > 0
        ) {
            const productId: string = cashRetailProduct.product.id;

            let index: number = this.callService.call.cashProducts.findIndex(
                (cashProduct) => cashProduct.id === cashRetailProduct.id
            );
            if (index !== -1) {
                this.callService.call.cashProducts.splice(index, 1);
                await this.callService.saveCallAndNotify();

                index = this.callService.call.cashProducts.findIndex(
                    (cashProduct) => cashProduct.productId === productId
                );

                if (index === -1) {
                    //we just removed the last productId of its kind so kill it on the model.
                    await this.callService.removeProductFromCash(productId);
                }

                if (
                    this.callService.call.callType === CallTypes.retail ||
                    this.callService.call?.callType === CallTypes.rmWholesale
                ) {
                    if (
                        !this.callService.call.cashProducts?.length &&
                        !this.callService.call.gratisProducts?.length &&
                        !this.callService.call.exchangeInProducts?.length &&
                        !this.callService.call.exchangeOutProducts?.length
                    ) {
                        this.callService.call.shouldVisitTax = false;
                        await this.callService.saveCallAndNotify();
                    } else {
                        await this.setTaxValidation();
                    }
                }

                this.stepperCallApplicationService.shouldBuildProducts = true;
                await this.buildCashProductViewModel();
                this.calculateTotals();
            }
        }
    }

    async removeGratisProduct(
        gratisRetailProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (
            this.callService &&
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.gratisProducts &&
            this.callService.call.gratisProducts.length > 0
        ) {
            await this.callService.removeAProductFromGratisById(
                gratisRetailProduct.product.id
            );

            if (
                this.callService.call.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale
            ) {
                if (
                    !this.callService.call.cashProducts?.length &&
                    !this.callService.call.gratisProducts?.length &&
                    !this.callService.call.exchangeInProducts?.length &&
                    !this.callService.call.exchangeOutProducts?.length
                ) {
                    this.callService.call.shouldVisitTax = false;
                    await this.callService.saveCallAndNotify();
                } else {
                    await this.setTaxValidation();
                }
            }

            this.stepperCallApplicationService.shouldBuildProducts = true;
            await this.buildGratisProductViewModel();
            this.calculateTotals();
        }
    }

    async removeOrderProduct(op: CallOrderProductViewModel): Promise<void> {
        if (
            this.callService &&
            (this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call.orderProducts &&
            this.callService.call.orderProducts.length > 0
        ) {
            const productId: string = op.product.id;

            let index: number = this.callService.call.orderProducts.findIndex(
                (orderProduct) => orderProduct.id === op.id
            );
            if (index !== -1) {
                this.callService.call.orderProducts.splice(index, 1);
                await this.callService.saveCallAndNotify();
                await this.callService.removeProductFromOrder(
                    productId,
                    op.wholesaler?.length ? op.wholesaler[0].wholesaler?.id : ""
                );

                this.stepperCallApplicationService.shouldBuildProducts = true;
                this.buildOrderProductViewModel();
                this.stepperCallApplicationService.salesAndGratisWholesaleHasBeenUpdated = true;
            }
            this.runValidation();
        }
    }

    runValidation(): void {
        const callValidation = this.callValidationService.isCallStepValid(
            RetailStepperStep.salesGratis
        );

        this.validationErrorMessages = callValidation
            .filter((cv) => cv.errorLevel === ErrorLevel.invalid)
            .map((cv) => cv.message);
    }

    async saveCashProduct(
        cashProduct: CallCashProductViewModel,
        save: boolean = true
    ): Promise<void> {
        this.callService.isFinalRetailReceiptPrinted = false;
        if (cashProduct) {
            let valid = true;
            if (cashProduct.price && cashProduct.price < 0) {
                cashProduct.price = 0;
                valid = false;
            }

            if (cashProduct.discount && cashProduct.discount < 0) {
                cashProduct.discount = 0;
                valid = false;
            }

            if (!valid) {
                return;
            }
        }

        if (
            this.callService.call?.callType === CallTypes.retail ||
            this.callService.call?.callType === CallTypes.rmWholesale
        ) {
            this.callService.call.cashProducts ??= [];

            const cashIndex: number = this.callService.call.cashProducts.findIndex(
                (product) => product.id === cashProduct.id
            );

            if (cashIndex !== -1) {
                const newCashProduct: CallCashProduct = new CallCashProduct();
                newCashProduct.id = cashProduct.id;
                newCashProduct.discount = cashProduct.discount;
                newCashProduct.price = cashProduct.price;
                newCashProduct.productId = cashProduct.product?.id;
                newCashProduct.quantity = cashProduct.quantity;
                newCashProduct.units = cashProduct.units;
                newCashProduct.upc = cashProduct.upc;

                this.callService.call.cashProducts.splice(
                    cashIndex,
                    1,
                    newCashProduct
                );

                if (save) {
                    await this.callService.saveCallAndNotify();
                }
            }
            if (save) {
                this.buildCashProductViewModel();
                this.calculateTotals();
            }
        }
    }

    async saveGratisProduct(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        this.callService.isFinalRetailReceiptPrinted = false;
        if (gratisProduct) {
            let valid = true;
            if (gratisProduct.value && gratisProduct.value < 0) {
                gratisProduct.value = 0;
                valid = false;
            }

            if (!valid) {
                return;
            }
        }

        if (
            this.callService.call?.callType === CallTypes.retail ||
            this.callService.call?.callType === CallTypes.rmWholesale
        ) {
            if (!this.callService.call.gratisProducts) {
                this.callService.call.gratisProducts = [];
            }

            const gratisIndex: number = this.callService.call.gratisProducts.findIndex(
                (product) => product.id === gratisProduct.id
            );

            if (gratisIndex !== -1) {
                const newGratisProduct: CallGratisProduct = new CallGratisProduct();
                newGratisProduct.id = gratisProduct.id;
                newGratisProduct.value = gratisProduct.value;
                newGratisProduct.productId = gratisProduct.product?.id;
                newGratisProduct.quantity = gratisProduct.quantity;
                newGratisProduct.units = gratisProduct.units;
                newGratisProduct.upc = gratisProduct.upc;

                this.callService.call.gratisProducts.splice(
                    gratisIndex,
                    1,
                    newGratisProduct
                );
                await this.callService.saveCallAndNotify();
            }
            this.buildGratisProductViewModel();
            this.calculateTotals();
        }
    }

    async saveOrderProduct(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (
            this.callService.call?.callType === CallTypes.retail ||
            this.callService.call?.callType === CallTypes.rmWholesale
        ) {
            this.callService.isFinalWholesaleReceiptPrinted = false;
            this.callService.call.orderProducts ??= [];

            const orderIndex: number = this.callService.call.orderProducts.findIndex(
                (op) => op.id === orderProduct.id
            );

            if (orderIndex !== -1) {
                const oldProductOrderProductIdAndWholesalerId =
                    this.callService.call.orderProducts[orderIndex].productId +
                    (this.callService.call.orderProducts[orderIndex]
                        .wholesalerCustomerId ?? "");
                const oldProductOrderIndex = this.callService.call.productsOrder.findIndex(
                    (po) => po === oldProductOrderProductIdAndWholesalerId
                );

                const newOrderProduct: CallOrderProduct = new CallOrderProduct();
                newOrderProduct.id = orderProduct.id;
                newOrderProduct.uin = orderProduct.uin;
                newOrderProduct.productId = orderProduct.product?.id;
                newOrderProduct.quantity = orderProduct.quantity;
                newOrderProduct.units = orderProduct.units;
                newOrderProduct.upc = orderProduct.upc;
                newOrderProduct.uin = orderProduct.uin;
                newOrderProduct.selectedProject = orderProduct.selectedProject;

                newOrderProduct.callOrderDates = orderProduct.orderDates;

                //If the order date quantity doesn't sum up to the products' quantity, add to the
                //first order date until it does.
                if (newOrderProduct.callOrderDates) {
                    const curTotal = newOrderProduct.callOrderDates.reduce(
                        (acc, d) => acc += d.quantity, 0
                    );
                    if (curTotal != orderProduct.quantity) {
                        newOrderProduct.callOrderDates[0].quantity += orderProduct.quantity - curTotal;
                    }
                }
                newOrderProduct.wholesalerCustomerId = orderProduct.wholesaler
                    ?.length
                    ? orderProduct.wholesaler[0].id
                    : null;
                newOrderProduct.storeCount = orderProduct.storeCount;

                this.callService.call.orderProducts.splice(
                    orderIndex,
                    1,
                    newOrderProduct
                );
                this.callService.call.productsOrder.splice(
                    oldProductOrderIndex,
                    1,
                    newOrderProduct.productId +
                        (newOrderProduct.wholesalerCustomerId ?? "")
                );
                await this.callService.saveCallAndNotify();
            }
            this.runValidation();
        }
    }

    setOrderOptions(callOrderProductViewModel: CallOrderProductViewModel): void {
        callOrderProductViewModel.employee = this.employee;
        if (!callOrderProductViewModel.selectedProject && (this.callService.call as RetailCall | RmWholesaleCall).selectedProject) {
            callOrderProductViewModel.selectedProject = Object.assign({}, (this.callService.call as RetailCall | RmWholesaleCall).selectedProject);
        }

        this.swisherOverlayRef = this.overlayService.open(OrderDateOptionsDialogComponent, callOrderProductViewModel);

        this.swisherOverlayRef.blocking = true;
        this.swisherOverlayRef.afterClosed$.subscribe((rtn) => {
            if (rtn.data?.isConfirmed) {
                const index = this.stepperCallApplicationService.callOrderProductViewModels.findIndex((op) => op.id === rtn.data.id);
                if (index !== -1) {
                    this.stepperCallApplicationService.callOrderProductViewModels.splice(index, 1, rtn.data);
                    this.saveOrderProduct(rtn.data);
                }
            }
        });
    }

    async validationCashProductQuantity(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        if (cashProduct.quantity !== cashProduct.oldQuantity) {
            cashProduct.quantity = Helper.validateMin(cashProduct.quantity);

            if (cashProduct.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(cashProduct);
            } else {
                cashProduct.oldQuantity = cashProduct.quantity;
                await this.saveCashProduct(cashProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async validationCashProductUnit(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        if (cashProduct.units !== cashProduct.oldUnits) {
            cashProduct.units = Helper.validateMin(cashProduct.units);

            if (cashProduct.units >= this.maxUnitNumber) {
                this.openUnitWarning(cashProduct);
            } else {
                cashProduct.oldUnits = cashProduct.units;
                await this.saveCashProduct(cashProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async setTaxValidation(): Promise<void> {
        if (this.isTaxAvailable) {
            (this.callService.call as RetailCall).shouldVisitTax = true;
            await this.callService.saveCallAndNotify();
        }
    }

    async validationCashProductPrice(
        cashProduct: CallCashProductViewModel
    ): Promise<void> {
        if (cashProduct.price !== cashProduct.oldPrice) {
            cashProduct.price = Helper.validateMin(cashProduct.price, 0, true);

            if (cashProduct.price >= this.maxPriceNumber) {
                this.openCashPriceWarning(cashProduct);
            } else {
                cashProduct.oldPrice = cashProduct.price;
                await this.saveCashProduct(cashProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async validationGratisProductQuantity(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (gratisProduct.quantity !== gratisProduct.oldQuantity) {
            gratisProduct.quantity = Helper.validateMin(
                gratisProduct.quantity,
                1,
                true
            );

            if (gratisProduct.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(gratisProduct);
            } else {
                gratisProduct.oldQuantity = gratisProduct.quantity;
                await this.saveGratisProduct(gratisProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async validationGratisProductUnit(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (gratisProduct.units !== gratisProduct.oldUnits) {
            gratisProduct.units = Helper.validateMin(gratisProduct.units);

            if (gratisProduct.units >= this.maxUnitNumber) {
                this.openUnitWarning(gratisProduct);
            } else {
                gratisProduct.oldUnits = gratisProduct.units;
                await this.saveGratisProduct(gratisProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async validationGratisProductPrice(
        gratisProduct: CallGratisProductViewModel
    ): Promise<void> {
        if (gratisProduct.value !== gratisProduct.oldValue) {
            gratisProduct.value = Helper.validateMin(
                gratisProduct.value,
                0,
                true
            );

            if (gratisProduct.value >= this.maxPriceNumber) {
                this.openGratisPriceWarning(gratisProduct);
            } else {
                gratisProduct.oldValue = gratisProduct.value;
                await this.saveGratisProduct(gratisProduct);
                this.calculateTotals();
            }

            await this.setTaxValidation();
        }
    }

    async validationOrderProductQuantity(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (orderProduct.quantity !== orderProduct.oldQuantity) {
            orderProduct.quantity = Helper.validateMin(orderProduct.quantity);

            if (orderProduct.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(orderProduct);
            } else {
                orderProduct.oldQuantity = orderProduct.quantity;
                await this.saveOrderProduct(orderProduct);
            }
        }
    }

    async validationOrderProductUnit(
        orderProduct: CallOrderProductViewModel
    ): Promise<void> {
        if (orderProduct.units !== orderProduct.oldUnits) {
            orderProduct.units = Helper.validateMin(orderProduct.units);

            if (orderProduct.units >= this.maxUnitNumber) {
                this.openUnitWarning(orderProduct);
            } else {
                orderProduct.oldUnits = orderProduct.units;
                await this.saveOrderProduct(orderProduct);
            }
        }
    }

    //Private methods
    private selectProductDialog(): void {
        const data: CallDistributionViewModel = new CallDistributionViewModel();
        this.stepperCallApplicationService.shouldBuildProducts = true;
        data.mode = this.mode;
        data.isOrderMode = this.isOrderMode;
        const myRef = this.overlayService.open(
            DistributionDialogComponent,
            data
        );

        if (this.callService.call?.callType === CallTypes.retail) {
            data.callType = CallTypes.retail;
        } else if (this.callService.call?.callType === CallTypes.rmWholesale) {
            data.callType = CallTypes.rmWholesale;
        }

        let cashProductIds = new Array<string>();
        let gratisProductIds = new Array<string>();
        let exchangeInProductIds = new Array<string>();
        let exchangeOutProductIds = new Array<string>();

        if (
            this.callService.call.callType === CallTypes.retail ||
            this.callService.call.callType === CallTypes.rmWholesale
        ) {
            cashProductIds = this.callService.call.cashProducts
                .map((cp) => cp.id)
                .sort();
            gratisProductIds = this.callService.call.gratisProducts
                .map((gp) => gp.id)
                .sort();
            exchangeInProductIds = this.callService.call.exchangeInProducts
                .map((eip) => eip.id)
                .sort();
            exchangeOutProductIds = this.callService.call.exchangeOutProducts
                .map((eop) => eop.id)
                .sort();
        }

        myRef.afterClosed$.subscribe(async () => {
            this.stepperCallApplicationService.shouldBuildProducts = true;
            await this.buildObjects();

            if (
                this.callService.call.callType === CallTypes.retail ||
                this.callService.call.callType === CallTypes.rmWholesale
            ) {
                if (
                    cashProductIds.length !==
                        (this.callService.call.cashProducts?.length ?? 0) ||
                    gratisProductIds.length !==
                        (this.callService.call.gratisProducts?.length ?? 0) ||
                    exchangeInProductIds.length !==
                        (this.callService.call.exchangeInProducts?.length ??
                            0) ||
                    exchangeOutProductIds.length !==
                        (this.callService.call.exchangeOutProducts?.length ?? 0)
                ) {
                    await this.setTaxValidation();
                } else {
                    // same length assumed here due to the check above
                    const newCashProductIds = this.callService.call.cashProducts
                        .map((cp) => cp.id)
                        .sort();
                    const newGratisProductIds = this.callService.call.gratisProducts
                        .map((gp) => gp.id)
                        .sort();
                    const newExchangeInProductIds = this.callService.call.exchangeInProducts
                        .map((eip) => eip.id)
                        .sort();
                    const newExchangeOutProductIds = this.callService.call.exchangeOutProducts
                        .map((eop) => eop.id)
                        .sort();

                    if (
                        !(
                            cashProductIds.every((cp) =>
                                newCashProductIds.includes(cp)
                            ) &&
                            gratisProductIds.every((gp) =>
                                newGratisProductIds.includes(gp)
                            ) &&
                            exchangeInProductIds.every((gp) =>
                                newExchangeInProductIds.includes(gp)
                            ) &&
                            exchangeOutProductIds.every((gp) =>
                                newExchangeOutProductIds.includes(gp)
                            )
                        )
                    ) {
                        await this.setTaxValidation();
                    }
                }
            }
        });
    }

    openQuantityWarning(
        product:
            | CallCashProductViewModel
            | CallOrderProductViewModel
            | CallGratisProductViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the quantity higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.quantity = product.oldQuantity;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallCashProductViewModel:
                        if (product.oldQuantity === product.quantity) {
                            product.quantity++;
                        }
                        product.oldQuantity = product.quantity;
                        this.saveCashProduct(
                            product as CallCashProductViewModel,
                            true
                        );
                        this.calculateTotals();
                        break;

                    case product instanceof CallOrderProductViewModel:
                        if (product.oldQuantity === product.quantity) {
                            product.quantity++;
                        }
                        product.oldQuantity = product.quantity;
                        this.saveOrderProduct(
                            product as CallOrderProductViewModel
                        );

                        break;

                    case product instanceof CallGratisProductViewModel:
                        if (product.oldQuantity === product.quantity) {
                            product.quantity++;
                        }
                        product.oldQuantity = product.quantity;
                        this.saveGratisProduct(
                            product as CallGratisProductViewModel
                        );
                        this.calculateTotals();
                        break;

                    default:
                        break;
                }
            }
        });
    }

    openUnitWarning(
        product:
            | CallCashProductViewModel
            | CallOrderProductViewModel
            | CallGratisProductViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Sticks/Units higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.units = product.oldUnits;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallCashProductViewModel:
                        if (product.oldUnits === product.units) {
                            product.units++;
                        }
                        product.oldUnits = product.units;
                        this.saveCashProduct(
                            product as CallCashProductViewModel,
                            true
                        );
                        this.calculateTotals();
                        break;

                    case product instanceof CallOrderProductViewModel:
                        if (product.oldUnits === product.units) {
                            product.units++;
                        }
                        product.oldUnits = product.units;
                        this.saveOrderProduct(
                            product as CallOrderProductViewModel
                        );

                        break;

                    case product instanceof CallGratisProductViewModel:
                        if (product.oldUnits === product.units) {
                            product.units++;
                        }
                        product.oldUnits = product.units;
                        this.saveGratisProduct(
                            product as CallGratisProductViewModel
                        );
                        this.calculateTotals();
                        break;

                    default:
                        break;
                }
            }
        });
    }

    openCashPriceWarning(product: CallCashProductViewModel): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Price higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.price = product.oldPrice ?? 0;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                product.oldPrice = product.price;
                this.saveCashProduct(product, true);
                this.calculateTotals();
            }
        });
    }

    openGratisPriceWarning(product: CallGratisProductViewModel): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Value higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.value = product.oldValue ?? 0;
            this.confirmationOverlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                product.oldValue = product.value;
                this.saveGratisProduct(product);
                this.calculateTotals();
            }
        });
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }

    //private methods
    private reportNoAvailableCopyRecord(): void {
        const data = new ConfirmationDialogViewmodel();
        data.header = "Alert";
        data.message = "No unmodified record found to copy to.";

        this.overlayService.open(ConfirmationDialogComponent, data);
    }
}
