import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { Product } from "src/app/entity-models/product.entity";

export class CallGratisProductViewModel {
    id: string; //UUID
    product: Product;
    quantity: number;
    units: number;
    value: number;
    oldQuantity: number;
    oldUnits: number;
    oldValue: number;
    upc: string;
    total: number;
    totalWithTax: number;

    stateTaxAmount: number;
    countyTaxAmount: number;
    cityTaxAmount: number;
    statePrepaid: boolean;
    cityPrepaid: boolean;
    countyPrepaid: boolean;
    unitsOfMeasure: ProductUpc[];
    get isPristine(): boolean {
        return this.quantity === 1 &&
        this.units === (this.product.returnableUpc?.noOfEaches ?? 1) &&
        this.value === 0;
    }

    copyTo(
        gratisProduct: CallGratisProductViewModel
    ): CallGratisProductViewModel {
        gratisProduct.oldQuantity = gratisProduct.quantity;
        gratisProduct.oldUnits = gratisProduct.units;
        gratisProduct.oldValue = gratisProduct.value;
        gratisProduct.quantity = this.quantity;
        gratisProduct.units = this.units;
        gratisProduct.value = this.value;
        return gratisProduct;
    }
}
