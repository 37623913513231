import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { Product } from "src/app/entity-models/product.entity";

export class CallCashProductViewModel {
    id: string; //UUID
    product: Product;
    quantity: number;
    units: number;
    price: number;
    discount: number;
    oldQuantity: number;
    oldUnits: number;
    oldPrice: number;
    upc: string;
    stateTaxAmount: number;
    countyTaxAmount: number;
    cityTaxAmount: number;
    statePrepaid: boolean;
    cityPrepaid: boolean;
    countyPrepaid: boolean;
    total: number;
    totalWithTax: number;
    unitsOfMeasure: ProductUpc[];

    get isPristine(): boolean {
        return (
            this.quantity === 1 &&
            this.units === (this.product.lowestSellableUpc?.noOfEaches ?? 1) &&
            this.price === 0 &&
            this.discount === 0
        );
    }

    copyTo(
        cashProduct: CallCashProductViewModel
    ): CallCashProductViewModel {
        cashProduct.oldQuantity = cashProduct.quantity;
        cashProduct.oldUnits = cashProduct.units;
        cashProduct.oldPrice = cashProduct.price;
        cashProduct.quantity = this.quantity;
        cashProduct.units = this.units;
        cashProduct.price = this.price;
        cashProduct.discount = this.discount;
        return cashProduct;
    }
}
