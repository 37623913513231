<ng-container *ngIf="this.call">
    <mat-card appearance="outlined" class="mt-3">
        <div *ngIf="validationErrorMessages && validationErrorMessages.length > 0">
            <div class="alert alert-danger" role="alert">
                <div class="row">
                    <b>Validation Summary</b>
                </div>
                <div *ngFor="let message of validationErrorMessages">
                    <mat-icon>warning</mat-icon>
                    {{ message }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <h1>Receipts</h1>
            </div>
            <div class="col-4">
                <mat-form-field *ngIf="isTaxState" class="print-format-dropdown" appearance="outline" color="accent">
                    <mat-label>Receipt Format</mat-label>
                    <mat-select [value]="selectedFormat" (valueChange)="onFormatChange($event)">
                        <mat-option *ngFor="let option of formatOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <app-retail-receipt #retailReceipt [signature]="signature" [selectedContact]="call.selectedContact"
                    [customer]="customer" [customerReceiptAddress]="selectedCustomerReceiptAddress"
                    [isTaxState]="isTaxState" [selectedFormat]="selectedFormat" [employee]="employee">
                </app-retail-receipt>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 vl">
                <app-wholesale-receipt #wholesaleReceipt [signature]="signature"
                    [selectedContact]="call.selectedContact" [customer]="customer"
                    [customerReceiptAddress]="selectedCustomerReceiptAddress" [employee]="employee"
                    (wholesaleDraftCapture)="onPrintWholesaleDraft()">
                </app-wholesale-receipt>
            </div>
            <div *ngIf="hasReceipts" class="col-lg-4 col-md-4 col-md-12">
                <mat-card appearance="outlined" class="inner-card">
                    <div class="container">
                        <div *ngIf="hasReceipts" class="row">
                            <div class="col larger s-teal-color">
                                <b>Customer Address</b>
                            </div>
                        </div>
                        <div *ngIf="hasReceipts" class="row d-flex align-items-center">
                            <div class="col">
                                <mat-form-field class="address-dropdown" appearance="outline" color="accent">
                                    <mat-select [(value)]="selectedCustomerReceiptAddress">
                                        <mat-option *ngFor="let option of customerReceiptAddressOptions" [value]="option">
                                            {{ option.addressName }}, {{ option.addressFormatted }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <ng-container *ngIf="showPrintDraftReceipts">
                            <hr />
                            <div *ngIf="hasRetailReceipts" class="row d-flex align-items-center"
                                [ngClass]="{'border-bottom': (hasRetailReceipts && hasWholesaleReceipts)}">
                                <div class="col">Draft Retailer Receipt</div>
                                <div class="col">
                                    <button appThrottleClick class="btn btn-sm btn-white-wide pull-right green pull-right actionBtn"
                                        (throttledClick)="onPrintRetailDraft()">
                                        <span class="flaticon-printer larger"></span>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="hasWholesaleReceipts" class="row d-flex align-items-center">
                                <div class="col">Draft Wholesaler Receipt</div>
                                <div class="col">
                                    <button appThrottleClick class="btn btn-sm btn-white-wide pull-right green pull-right actionBtn"
                                        (throttledClick)="onPrintWholesaleDraft()">
                                        <span class="flaticon-printer larger"></span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>

                        <hr />
                        <div class="row">
                            <div class="col larger s-teal-color">
                                <b>Signature</b>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div *ngIf="contactOptions" class="col-10">
                                <mat-form-field class="inherit-width" appearance="outline">
                                    <mat-label>Please Select a Contact</mat-label>
                                    <mat-select [(value)]="call.selectedContact"
                                        (selectionChange)="onSelectedContact()" [compareWith]="compareContactOptions">
                                        <mat-option *ngFor="let option of contactOptions" [value]="option">
                                            {{ option.firstName }}
                                            {{ option.lastName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2 small">
                                <button appThrottleClick class="btn btn-sm sw-green-btn border" (throttledClick)="onAddContact()">
                                    <span class="flaticon-add"></span>
                                </button>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-center justify-content-xl-start">
                            <div class="col-6 col-xl-3">
                                <button appThrottleClick [disabled]="isSignatureDisabled
                                    || this.callService.isFinalRetailReceiptPrinted
                                    || this.callService.isEmailFinalRetailReceipt
                                    || this.callService.isFinalWholesaleReceiptPrinted
                                    || this.callService.isEmailFinalWholesaleReceipt"
                                    class="btn btn-sm btn-gray border"
                                    (throttledClick)="openSinatureModal()">
                                    <span>CLICK TO SIGN</span>
                                </button>
                            </div>
                            <div class="col-12 col-xl-9">
                                <img *ngIf="signature" [src]="signature" alt="Signature" [width]="signatureWidth"
                                    [height]="signatureHeight" />
                            </div>
                        </div>
                        <hr />
                        <div *ngIf="signature && hasRetailReceipts" class="row d-flex align-items-center"
                            [ngClass]="{'border-bottom': (hasRetailReceipts && hasWholesaleReceipts)}">
                            <div class="col">Final Retailer Receipt</div>
                            <div class="col d-flex justify-content-end">
                                <button appThrottleClick #ngxPrintRetailFinal
                                    class="btn btn-sm btn-white-wide actionBtn mr-1"
                                    (throttledClick)="openModal(true, true)"
                                    title="Print Retailer Receipt"
                                    [disabled]="isSignatureDisabled">
                                    <span class="flaticon-printer larger"
                                        [ngClass]="{'green': !isSignatureDisabled}">
                                    </span>
                                </button>
                                <button class="btn btn-sm btn-white-wide actionBtn"
                                    (click)="openModal(true, false)"
                                    [disabled]="isSignatureDisabled || callService.isEmailFinalRetailReceipt" title="Send Retailer Receipt">
                                    <span class="flaticon-send larger"
                                        [ngClass]="{'green': !isSignatureDisabled && !callService.isEmailFinalRetailReceipt}">
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="hasWholesaleReceipts && signature" class="row d-flex align-items-center">
                            <div class="col">Final Wholesaler Receipt</div>
                            <div class="col d-flex justify-content-end">
                                <button appThrottleClick class="btn btn-sm btn-white-wide actionBtn mr-1"
                                    [disabled]="isSignatureDisabled || callService.isEmailFinalWholesaleReceipt"
                                    (throttledClick)="openModal(false, true)">
                                    <span class="flaticon-printer larger"
                                        [ngClass]="{'green': !isSignatureDisabled}">
                                    </span>
                                </button>
                                <button class="btn btn-sm btn-white-wide actionBtn"
                                    (click)="openModal(false, false)"
                                    [disabled]="isSignatureDisabled || callService.isEmailFinalWholesaleReceipt" title="Send Wholesaler Receipt">
                                    <span class="flaticon-send larger"
                                        [ngClass]="{'green': !isSignatureDisabled && !callService.isEmailFinalWholesaleReceipt}">
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div *ngIf="!hasReceipts" class="row">
            <div class="col-12">
                <h1>No Product Selected!</h1>
            </div>
        </div>
    </mat-card>
    <!-- For PDF rendering -->
    <div class="capture-render-area" [ngClass]="{'d-none': !isCapturing}">
        <app-retail-receipt class="capture" #retailReceiptCapture [signature]="signature" [selectedContact]="call.selectedContact"
            [customer]="customer" [customerReceiptAddress]="selectedCustomerReceiptAddress"
            [isTaxState]="isTaxState" [selectedFormat]="selectedFormat" [employee]="employee">
        </app-retail-receipt>
        <app-wholesale-receipt class="capture" #wholesaleReceiptCapture [signature]="signature"
            [selectedContact]="call.selectedContact" [customer]="customer"
            [customerReceiptAddress]="selectedCustomerReceiptAddress" [employee]="employee">
        </app-wholesale-receipt>
    </div>
</ng-container>
