import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { OverlayService } from "src/app/services/overlay.service";

import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { CallExchangeOutProduct } from "src/app/entity-models/call-exchange-out-product.entity";
import { CallExchangeInProduct } from "src/app/entity-models/call-exchange-in-product.entity";

import { Helper } from "src/app/helpers/helper";
import { CallDistributionViewModel } from "../../call-viewmodels/call-distribution.viewmodel";
import { DistributionDialogComponent } from "src/app/dialogs/distribution-dialog/distribution-dialog.component";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { DistributionActionItems } from "../stepper-call-enums/distribution-action-items";
import { CallDistributionService } from "../../call-services/call-distribution.service";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { CallService } from "../../call-services/call.service";
import { CallTypes } from "shield.shared";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { TaxCalculatorComponent } from "../tax-calculator/tax-calculator.component";
import { TaxCalculatorViewModel } from "../tax-calculator/tax-calculator.viewmodel";
import { CallProductOutViewModel } from "../../call-viewmodels/call-product-out.viewmodel";
import { CallProductInViewModel } from "../../call-viewmodels/call-product-in.viewmodel";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";
import { TaxRateDelineationService } from "src/app/services/delineation-services/tax-rate-delineation.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";

@Component({
    selector: "app-exchange",
    templateUrl: "./exchange.component.html",
    styleUrls: ["./exchange.component.scss"]
})
export class ExchangeComponent implements OnInit, OnDestroy {
    //Public vars
    readonly distributionGridModeProductOut: DistributionActionItems =
        DistributionActionItems.exchangeOut;
    isOrderMode: boolean;
    mode: DistributionActionItems;
    productInRetailSum = 0;
    productInWholesaleSum = 0;
    productOutRetailSum = 0;
    productOutWholesaleSum = 0;
    selectedIndexSubscription: Subscription;
    totalDiffRetail = 0;
    totalDiffWholesale = 0;
    maxNumber = 1000000;
    maxQuantityNumber = 500;
    maxUnitNumber = 500;
    maxPriceNumber = 500;
    overlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    validationErrorMessages: string[] = [];
    isFinalRetailReceiptPrinted = false;
    isFinalWholesaleReceiptPrinted = false;
    call: RetailCall;
    private callSubscription: Subscription;

    taxCalculatorOverlayRef: SwisherOverlayRef<
        TaxCalculatorViewModel,
        TaxCalculatorComponent
    >;

    public constructor(
        private callService: CallService,
        public stepperCallApplicationService: StepperCallApplicationService,
        private retailCallProductService: ProductDelineationService,
        private distributionService: CallDistributionService,
        private overlayService: OverlayService,
        private callValidationService: CallValidationService,
        private productDelineationService: ProductDelineationService,
        private customerStateService: CustomerStateService,
        private stateDelineationService: StateDelineationService,
        private taxRateDelineationService: TaxRateDelineationService
    ) {}

    public ngOnInit(): void {
        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                (selectedIndex) => {
                    if (this.call?.callType) {
                        if (
                            (this.call.callType === CallTypes.retail &&
                                selectedIndex >= RetailStepperStep.exchange) ||
                            (this.call.callType === CallTypes.rmWholesale &&
                                selectedIndex >= RmWholesaleStepperStep.exchange)
                        ) {
                            this.builtProductViewModels();
                        }
                    }
                }
            );
        }

        if (!this.callSubscription || this.callSubscription.closed) {
            this.callSubscription = this.callService.observableCall.subscribe(
                (call) => {
                    if (
                        call?.callType === CallTypes.retail ||
                        call?.callType === CallTypes.rmWholesale
                    ) {
                        this.call = call;
                        this.isFinalRetailReceiptPrinted =
                            call.isFinalRetailReceiptPrinted || call.isEmailFinalRetailReceipt;
                        this.isFinalWholesaleReceiptPrinted =
                            call.isFinalWholesaleReceiptPrinted || call.isEmailFinalWholesaleReceipt;
                        this.runValidation();
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }

        if (this.callSubscription && !this.callSubscription.closed) {
            this.callSubscription.unsubscribe();
        }
    }

    //Public methods
    addProductIn(): void {
        this.isOrderMode = false;
        this.mode = DistributionActionItems.exchangeIn;
        this.selectProductDialog();
    }

    addProductOut(): void {
        this.isOrderMode = false;
        this.mode = DistributionActionItems.exchangeOut;
        this.selectProductDialog();
    }

    async setProductOutUPC(
        callProduct: CallProductOutViewModel,
        upc: string
    ): Promise<void> {
        callProduct.upc = upc;
        callProduct.units = callProduct.unitsOfMeasure.find(uom => uom.upc === upc)?.noOfEaches;
        await this.saveProductOut(callProduct);
        this.calculateTotals();
        await this.setTaxValidation();
    }

    async setProductInUPC(
        callProduct: CallProductInViewModel,
        upc: string
    ): Promise<void> {
        callProduct.upc = upc;
        callProduct.units = callProduct.unitsOfMeasure.find(uom => uom.upc === upc)?.noOfEaches;
        await this.saveProductIn(callProduct);
        this.calculateTotals();
        await this.setTaxValidation();
    }

    async adjustSalable(productOut: CallProductOutViewModel): Promise<void> {
        if (!productOut.product) return;

        if (
            (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && !this.isFinalRetailReceiptPrinted
        ) {
            this.callService.call.unsalableProductIds ??= [];

            if (productOut.unsalable) {
                if (
                    !this.callService.call.unsalableProductIds.includes(
                        productOut.product.id
                    )
                ) {
                    this.callService.call.unsalableProductIds.push(
                        productOut.product.id
                    );
                }
            } else {
                const index: number = this.callService.call.unsalableProductIds.findIndex(
                    (product) => product === productOut.product.id
                );
                if (index !== -1) {
                    this.callService.call.unsalableProductIds.splice(index, 1);
                }
            }

            await this.callService.saveCallAndNotify();
            this.builtProductViewModels();
        }
    }

    buildProductInViewModels(): void {
        const productInViewModels: CallProductInViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            this.callService.call.exchangeInProducts ??= [];

            for (const productIn of this.callService.call.exchangeInProducts) {
                const retailCallProductInViewModel: CallProductInViewModel = new CallProductInViewModel();
                retailCallProductInViewModel.id = productIn.id;
                retailCallProductInViewModel.price = productIn.price;
                retailCallProductInViewModel.product = this.retailCallProductService.activeProducts.get(
                    productIn.productId
                );
                retailCallProductInViewModel.unitsOfMeasure = [...(retailCallProductInViewModel.product.upcs.length === 1 ? retailCallProductInViewModel.product.upcs : retailCallProductInViewModel.product.upcs.filter(upc => upc.uom !== 'Case'))];
                retailCallProductInViewModel.upc = productIn.upc;
                retailCallProductInViewModel.units = retailCallProductInViewModel.unitsOfMeasure.find(uom => uom.upc === retailCallProductInViewModel.upc)?.noOfEaches ?? retailCallProductInViewModel.unitsOfMeasure[0].noOfEaches;
                retailCallProductInViewModel.quantity = productIn.quantity;
                retailCallProductInViewModel.oldQuantity =
                    retailCallProductInViewModel.quantity;
                retailCallProductInViewModel.oldUnits = productIn.units;
                retailCallProductInViewModel.oldRetailPrice = productIn.price;
                retailCallProductInViewModel.oldWholesalePrice =
                    productIn.wholesalePrice;
                retailCallProductInViewModel.wholesalePrice =
                    productIn.wholesalePrice;
                retailCallProductInViewModel.upc = productIn.upc;
                retailCallProductInViewModel.stateTaxAmount =
                    productIn.callProductTax?.stateTaxAmount ?? 0;
                retailCallProductInViewModel.countyTaxAmount =
                    productIn.callProductTax?.countyTaxAmount ?? 0;
                retailCallProductInViewModel.cityTaxAmount =
                    productIn.callProductTax?.cityTaxAmount ?? 0;

                productInViewModels.push(retailCallProductInViewModel);
            }
        }

        this.calculateRetailWholesaleSums(productInViewModels);

        this.stepperCallApplicationService.callProductInViewModels = productInViewModels;
    }

    builtProductViewModels(): void {
        this.buildProductOutViewModels();
        this.buildProductInViewModels();
        this.calculateTotals();
        this.runValidation();
    }

    buildProductOutViewModels(): void {
        const productOutViewModels: CallProductOutViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            this.isFinalRetailReceiptPrinted = this.callService?.call?.isFinalRetailReceiptPrinted;
            this.callService.call.exchangeOutProducts ??= [];
            this.callService.call.unsalableProductIds ??= [];
            this.callService.call.inDistProductIds ??= [];

            for (const productOut of this.callService.call
                ?.exchangeOutProducts) {
                const retailCallProductOutViewModel: CallProductOutViewModel = new CallProductOutViewModel();
                retailCallProductOutViewModel.id = productOut.id;
                retailCallProductOutViewModel.price = productOut.price;
                retailCallProductOutViewModel.product = this.retailCallProductService.activeProducts.get(
                    productOut.productId
                );
                retailCallProductOutViewModel.unitsOfMeasure = [...(retailCallProductOutViewModel.product.upcs.length === 1 ? retailCallProductOutViewModel.product.upcs : retailCallProductOutViewModel.product.upcs.filter(upc => upc.uom !== 'Case'))];
                retailCallProductOutViewModel.upc = productOut.upc;
                retailCallProductOutViewModel.units = retailCallProductOutViewModel.unitsOfMeasure.find(uom => uom.upc === retailCallProductOutViewModel.upc)?.noOfEaches ?? retailCallProductOutViewModel.unitsOfMeasure[0].noOfEaches;
                retailCallProductOutViewModel.quantity = productOut.quantity;
                retailCallProductOutViewModel.oldQuantity
                    retailCallProductOutViewModel.quantity;
                retailCallProductOutViewModel.oldUnits = productOut.units;
                retailCallProductOutViewModel.oldRetailPrice = productOut.price;
                retailCallProductOutViewModel.oldWholesalePrice =
                    productOut.wholesalePrice;
                retailCallProductOutViewModel.upc = productOut.upc;
                retailCallProductOutViewModel.unsalable = this.callService.call.unsalableProductIds.includes(
                    productOut.productId
                );
                retailCallProductOutViewModel.wholesalePrice =
                    productOut.wholesalePrice;
                retailCallProductOutViewModel.isInDist = this.callService.call.inDistProductIds.includes(
                    productOut.productId
                );
                retailCallProductOutViewModel.stateTaxAmount =
                    productOut.callProductTax?.stateTaxAmount ?? 0;
                retailCallProductOutViewModel.countyTaxAmount =
                    productOut.callProductTax?.countyTaxAmount ?? 0;
                retailCallProductOutViewModel.cityTaxAmount =
                    productOut.callProductTax?.cityTaxAmount ?? 0;

                productOutViewModels.push(retailCallProductOutViewModel);
            }
        }

        this.calculateRetailWholesaleSums(productOutViewModels, true);

        this.stepperCallApplicationService.callProductOutViewModels = productOutViewModels;
    }

    calculateRetailWholesaleSums(
        viewmodels: (CallProductOutViewModel | CallProductInViewModel)[],
        out?: boolean
    ): void {
        if (out) {
            this.productOutRetailSum = viewmodels.reduce(
                (acc, product) => acc + product.price * product.quantity,
                0
            );
            this.productOutWholesaleSum = viewmodels.reduce(
                (acc, product) =>
                    acc + product.wholesalePrice * product.quantity,
                0
            );
        } else {
            this.productInRetailSum = viewmodels.reduce(
                (acc, product) => acc + product.price * product.quantity,
                0
            );
            this.productInWholesaleSum = viewmodels.reduce(
                (acc, product) =>
                    acc + product.wholesalePrice * product.quantity,
                0
            );
        }
    }

    calculateTotals(): void {
        this.totalDiffWholesale =
            this.stepperCallApplicationService.callProductInViewModels.reduce(
                (acc, product) =>
                    acc + product.wholesalePrice * product.quantity,
                0
            ) -
            this.stepperCallApplicationService.callProductOutViewModels.reduce(
                (acc, product) =>
                    acc + product.wholesalePrice * product.quantity,
                0
            );

        this.totalDiffRetail =
            this.stepperCallApplicationService.callProductInViewModels.reduce(
                (acc, product) => acc + product.price * product.quantity,
                0
            ) -
            this.stepperCallApplicationService.callProductOutViewModels.reduce(
                (acc, product) => acc + product.price * product.quantity,
                0
            );

        // WHY THIS CODE: SOMETIMES VALUE IS EXTREMELY SMALL, CAUSING EXCHANGE ERRORS WHEN THERE IS NOT. ALSO, SOMETIMES VALUE IS -0

         let retailRounded = Math.round(this.totalDiffRetail * 100) / 100
         let wholesaleRounded = Math.round(this.totalDiffWholesale * 100) / 100

         retailRounded = retailRounded == 0 ? Math.abs(retailRounded) : retailRounded
         wholesaleRounded = wholesaleRounded == 0 ? Math.abs(wholesaleRounded) : wholesaleRounded 
         
         if (Math.abs(retailRounded) < 0.01) {
            retailRounded = 0
         }

         if (Math.abs(wholesaleRounded) < 0.01) {
            wholesaleRounded = 0
         }
         
         this.totalDiffRetail = retailRounded
         this.totalDiffWholesale = wholesaleRounded

        this.stepperCallApplicationService.totalDiffRetail = this.totalDiffRetail;
        this.stepperCallApplicationService.totalDiffWholesale = this.totalDiffWholesale;
    }

    async copyProductOut(productOut: CallProductOutViewModel): Promise<void> {
        const startingIndex = this.stepperCallApplicationService.callProductOutViewModels.indexOf(
            productOut
        );
        let newProduct: CallProductOutViewModel;
        if (
            startingIndex !== -1 &&
            this.stepperCallApplicationService.callProductOutViewModels.length >
                startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.stepperCallApplicationService.callProductOutViewModels
                        .length && !newProduct;
                i++
            ) {
                if (
                    this.stepperCallApplicationService.callProductOutViewModels[
                        i
                    ].isPristine
                ) {
                    newProduct = productOut.copyTo(
                        this.stepperCallApplicationService
                            .callProductOutViewModels[i]
                    );
                    this.stepperCallApplicationService.callProductOutViewModels.splice(
                        i,
                        1,
                        newProduct
                    );

                    await this.setTaxValidation();
                }
            }
        }

        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            await this.saveProductOut(newProduct);
        }
    }

    async copyProductIn(productIn: CallProductInViewModel): Promise<void> {
        const startingIndex = this.stepperCallApplicationService.callProductInViewModels.indexOf(
            productIn
        );
        let newProduct: CallProductInViewModel;
        if (
            startingIndex !== -1 &&
            this.stepperCallApplicationService.callProductInViewModels.length >
                startingIndex
        ) {
            for (
                let i = startingIndex + 1;
                i <
                    this.stepperCallApplicationService.callProductInViewModels
                        .length && !newProduct;
                i++
            ) {
                if (
                    this.stepperCallApplicationService.callProductInViewModels[
                        i
                    ].isPristine
                ) {
                    newProduct = productIn.copyTo(
                        this.stepperCallApplicationService
                            .callProductInViewModels[i]
                    );
                    this.stepperCallApplicationService.callProductInViewModels.splice(
                        i,
                        1,
                        newProduct
                    );

                    await this.setTaxValidation();
                }
            }
        }

        if (!newProduct) {
            this.reportNoAvailableCopyRecord();
        } else {
            await this.saveProductIn(newProduct);
        }
    }

    async decrementProductInQuantity(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.quantity > 1) {
            productIn.quantity--;
            productIn.oldQuantity = productIn.quantity;
            await this.saveProductIn(productIn);

            await this.setTaxValidation();
        }
    }

    async decrementProductOutQuantity(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.quantity > 1) {
            productOut.quantity--;
            productOut.oldQuantity = productOut.quantity;
            await this.saveProductOut(productOut);

            await this.setTaxValidation();
        }
    }

    async incrementProductInQuantity(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(productIn);
        } else if (productIn.quantity < this.maxQuantityNumber) {
            productIn.quantity++;
            productIn.oldQuantity = productIn.quantity;
            await this.saveProductIn(productIn);
        }

        await this.setTaxValidation();
    }

    async incrementProductOutQuantity(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.quantity + 1 >= this.maxQuantityNumber) {
            this.openQuantityWarning(productOut);
        } else if (productOut.quantity < this.maxQuantityNumber) {
            productOut.oldQuantity = productOut.quantity;
            productOut.quantity++;
            await this.saveProductOut(productOut);
        }

        await this.setTaxValidation();
    }

    isInProductIn(productOut: CallProductOutViewModel): boolean {
        // product in the dist...all we are is product in the dist...
        return (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            ? !!this.callService.call.exchangeInProducts.find(
                  (productIn) => productOut.product.id === productIn.productId
              )
            : false;
    }

    runValidation(): void {
        const callValidation = this.callValidationService.isCallStepValid(
            RetailStepperStep.exchange
        );

        this.validationErrorMessages = callValidation.map((cv) => cv.message);
    }

    onClose(): void {
        this.taxCalculatorOverlayRef.close(this.taxCalculatorOverlayRef.data);
    }

    async onOpenTaxCalculator(): Promise<void> {
        const data: TaxCalculatorViewModel = new TaxCalculatorViewModel(
            this.callService,
            this.productDelineationService,
            this.customerStateService,
            this.stateDelineationService,
            this.taxRateDelineationService
        );
        data.headerLeftText = "Tax Calculator";
        data.buttonLeftFunction = () => this.onClose();

        await data.buildTaxCalculatorRecordViewModels();

        this.buildProductInViewModels();
        this.buildProductOutViewModels();

        this.taxCalculatorOverlayRef = this.overlayService.open(
            TaxCalculatorComponent,
            data,
            true
        );

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            this.callService.call.shouldVisitTax = false;
        }
        await this.callService.saveCallAndNotify();

        this.taxCalculatorOverlayRef.afterClosed$.subscribe(async () => {
            this.runValidation();
            await this.callService.saveCallAndNotify();
        });
    }

    async saveProductIn(productIn: CallProductInViewModel): Promise<void> {
        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            this.callService.isFinalRetailReceiptPrinted = false;
            if (productIn) {
                let valid = true;
                if (productIn.price && productIn.price < 0) {
                    productIn.price = 0;
                    valid = false;
                }

                if (productIn.wholesalePrice && productIn.wholesalePrice < 0) {
                    productIn.wholesalePrice = 0;
                    valid = false;
                }

                if (!valid) {
                    return;
                }
            }

            this.callService.call.exchangeInProducts ??= [];

            const productInIndex: number = this.callService.call.exchangeInProducts.findIndex(
                (product) => product.productId === productIn.product?.id
            );

            if (productInIndex !== -1) {
                const newProductIn: CallExchangeInProduct = new CallExchangeInProduct();
                newProductIn.id = productIn.id;
                newProductIn.price = productIn.price;
                newProductIn.wholesalePrice = productIn.wholesalePrice;
                newProductIn.productId = productIn.product?.id;
                newProductIn.quantity = productIn.quantity;
                newProductIn.units = productIn.units;
                newProductIn.upc = productIn.upc;

                this.callService.call.exchangeInProducts.splice(
                    productInIndex,
                    1,
                    newProductIn
                );
                await this.callService.saveCallAndNotify();
            }
            this.calculateRetailWholesaleSums(
                this.stepperCallApplicationService.callProductInViewModels
            );
            this.calculateTotals();
            this.runValidation();
        }
    }

    async saveProductOut(productOut: CallProductOutViewModel): Promise<void> {
        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            this.callService.isFinalRetailReceiptPrinted = false;
            if (productOut) {
                let valid = true;
                if (productOut.price && productOut.price < 0) {
                    productOut.price = 0;
                    valid = false;
                }

                if (
                    productOut.wholesalePrice &&
                    productOut.wholesalePrice < 0
                ) {
                    productOut.wholesalePrice = 0;
                    valid = false;
                }

                if (!valid) {
                    return;
                }
            }

            this.callService.call.exchangeOutProducts ??= [];

            const productOutIndex: number = this.callService.call.exchangeOutProducts.findIndex(
                (product) => product.productId === productOut.product?.id
            );

            if (productOutIndex !== -1) {
                const newProductOut: CallExchangeOutProduct = new CallExchangeOutProduct();
                newProductOut.id = productOut.id;
                newProductOut.price = productOut.price;
                newProductOut.wholesalePrice = productOut.wholesalePrice;
                newProductOut.productId = productOut.product?.id;
                newProductOut.quantity = productOut.quantity;
                newProductOut.units = productOut.units;
                newProductOut.upc = productOut.upc;

                this.callService.call.exchangeOutProducts.splice(
                    productOutIndex,
                    1,
                    newProductOut
                );
                await this.callService.saveCallAndNotify();
            }
            this.calculateRetailWholesaleSums(
                this.stepperCallApplicationService.callProductOutViewModels,
                true
            );
            this.calculateTotals();
            this.runValidation();
        }
    }

    async validationProductInQuantity(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.quantity !== productIn.oldQuantity) {
            productIn.quantity = Helper.validateMin(productIn.quantity, 1);

            if (productIn.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(productIn);
            } else {
                productIn.oldQuantity = productIn.quantity;
                await this.saveProductIn(productIn);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductInUnit(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.units !== productIn.oldUnits) {
            productIn.units = Helper.validateMin(productIn.units, 1);

            if (productIn.units >= this.maxUnitNumber) {
                this.openUnitWarning(productIn);
            } else {
                productIn.oldUnits = productIn.units;
                await this.saveProductIn(productIn);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductInRetailPrice(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.price !== productIn.oldRetailPrice) {
            productIn.price = Helper.validateMin(productIn.price, 0, true);

            if (productIn.price >= this.maxPriceNumber) {
                this.openPriceWarning(productIn);
            } else {
                productIn.oldRetailPrice = productIn.price;
                await this.saveProductIn(productIn);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductInWholesalePrice(
        productIn: CallProductInViewModel
    ): Promise<void> {
        if (productIn.wholesalePrice !== productIn.oldWholesalePrice) {
            productIn.wholesalePrice = Helper.validateMin(
                productIn.wholesalePrice,
                0,
                true
            );

            if (productIn.wholesalePrice >= this.maxPriceNumber) {
                this.openWholesalePriceWarning(productIn);
            } else {
                productIn.oldWholesalePrice = productIn.wholesalePrice;
                await this.saveProductIn(productIn);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductOutQuantity(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.quantity !== productOut.oldQuantity) {
            productOut.quantity = Helper.validateMin(productOut.quantity, 1);

            if (productOut.quantity >= this.maxQuantityNumber) {
                this.openQuantityWarning(productOut);
            } else {
                productOut.oldQuantity = productOut.quantity;
                await this.saveProductOut(productOut);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductOutUnit(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.units !== productOut.oldUnits) {
            productOut.units = Helper.validateMin(productOut.units, 1);

            if (productOut.units >= this.maxUnitNumber) {
                this.openUnitWarning(productOut);
            } else {
                productOut.oldUnits = productOut.units;
                await this.saveProductOut(productOut);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductOutRetailPrice(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.price !== productOut.oldRetailPrice) {
            productOut.price = Helper.validateMin(productOut.price, 0, true);

            if (productOut.price >= this.maxPriceNumber) {
                this.openPriceWarning(productOut);
            } else {
                productOut.oldRetailPrice = productOut.price;
                await this.saveProductOut(productOut);
            }

            await this.setTaxValidation();
        }
    }

    async validationProductOutWholesalePrice(
        productOut: CallProductOutViewModel
    ): Promise<void> {
        if (productOut.wholesalePrice !== productOut.oldWholesalePrice) {
            productOut.wholesalePrice = Helper.validateMin(
                productOut.wholesalePrice,
                0,
                true
            );

            if (productOut.wholesalePrice >= this.maxPriceNumber) {
                this.openWholesalePriceWarning(productOut);
            } else {
                productOut.oldWholesalePrice = productOut.wholesalePrice;
                await this.saveProductOut(productOut);
            }

            await this.setTaxValidation();
        }
    }

    async removeFromDist(productOut: CallProductOutViewModel): Promise<void> {
        if (
            (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && !this.isFinalRetailReceiptPrinted
        ) {
            this.callService.call.inDistProductIds ??= [];

            const result = await this.distributionService.addOrRemoveFromDist(
                productOut.product
            );
            productOut.isInDist = result.isInDist;
            this.stepperCallApplicationService.shouldBuildProducts = true;
        }
    }

    async removeProductIn(productIn: CallProductInViewModel): Promise<void> {
        if (
            this.callService
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.exchangeInProducts
            && this.callService.call.exchangeInProducts.length > 0
        ) {
            await this.callService.removeAProductFromProductInById(
                productIn.product.id
            );

            if (this.callService.call.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale) {
                if (
                    !this.callService.call.cashProducts?.length &&
                    !this.callService.call.gratisProducts?.length &&
                    !this.callService.call.exchangeInProducts?.length &&
                    !this.callService.call.exchangeOutProducts?.length
                ) {
                    this.callService.call.shouldVisitTax = false;
                    await this.callService.saveCallAndNotify();
                } else {
                    await this.setTaxValidation();
                }
            }

            this.builtProductViewModels();
        }
    }

    async removeProductOut(productOut: CallProductOutViewModel): Promise<void> {
        if (
            this.callService
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
            && this.callService.call.exchangeOutProducts
            && this.callService.call.exchangeOutProducts.length > 0
        ) {
            this.callService.call.unsalableProductIds ??= [];
            await this.callService.removeAProductFromProductOutById(
                productOut.product.id
            );
            if (
                productOut.product &&
                productOut.unsalable &&
                this.callService.call.unsalableProductIds.includes(
                    productOut.product.id
                )
            ) {
                const index: number = this.callService.call.unsalableProductIds.findIndex(
                    (unsaleacble) => unsaleacble === productOut.product.id
                );
                if (index !== -1) {
                    this.callService.call.unsalableProductIds.splice(index, 1);
                }
            }

            if (this.callService.call.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale) {
                if (
                    !this.callService.call.cashProducts?.length &&
                    !this.callService.call.gratisProducts?.length &&
                    !this.callService.call.exchangeInProducts?.length &&
                    !this.callService.call.exchangeOutProducts?.length
                ) {
                    this.callService.call.shouldVisitTax = false;
                    await this.callService.saveCallAndNotify();
                } else {
                    await this.setTaxValidation();
                }
            }

            this.builtProductViewModels();
        }
    }

    //Private methods
    private reportNoAvailableCopyRecord(): void {
        const data = new ConfirmationDialogViewmodel();
        data.header = "Alert";
        data.message = "No unmodified record found to copy to.";

        this.overlayService.open(ConfirmationDialogComponent, data);
    }

    private selectProductDialog(): void {
        this.stepperCallApplicationService.shouldBuildProducts = true;
        const data: CallDistributionViewModel = new CallDistributionViewModel();
        data.mode = this.mode;
        data.isOrderMode = this.isOrderMode;
        const myRef = this.overlayService.open(
            DistributionDialogComponent,
            data
        );

        if (this.callService.call.callType === CallTypes.retail) {
            data.callType = CallTypes.retail;
        } else if (this.callService.call.callType === CallTypes.rmWholesale) {
            data.callType = CallTypes.rmWholesale;
        }

        let cashProductIds = new Array<string>();
        let gratisProductIds = new Array<string>();
        let exchangeInProductIds = new Array<string>();
        let exchangeOutProductIds = new Array<string>();

        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            cashProductIds = this.callService.call.cashProducts
                .map((cp) => cp.id)
                .sort();
            gratisProductIds = this.callService.call.gratisProducts
                .map((gp) => gp.id)
                .sort();
            exchangeInProductIds = this.callService.call.exchangeInProducts
                .map((eip) => eip.id)
                .sort();
            exchangeOutProductIds = this.callService.call.exchangeOutProducts
                .map((eop) => eop.id)
                .sort();
        }

        myRef.afterClosed$.subscribe(() => {
            this.builtProductViewModels();

            if (this.callService.call.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale) {
                if (
                    cashProductIds.length !==
                        (this.callService.call.cashProducts?.length ?? 0) ||
                    gratisProductIds.length !==
                        (this.callService.call.gratisProducts?.length ?? 0) ||
                    exchangeInProductIds.length !==
                        (this.callService.call.exchangeInProducts?.length ??
                            0) ||
                    exchangeOutProductIds.length !==
                        (this.callService.call.exchangeOutProducts?.length ?? 0)
                ) {
                    this.setTaxValidation();
                } else {
                    // same length assumed here due to the check above
                    const newCashProductIds = this.callService.call.cashProducts
                        .map((cp) => cp.id)
                        .sort();
                    const newGratisProductIds = this.callService.call.gratisProducts
                        .map((gp) => gp.id)
                        .sort();
                    const newExchangeInProductIds = this.callService.call.exchangeInProducts
                        .map((eip) => eip.id)
                        .sort();
                    const newExchangeOutProductIds = this.callService.call.exchangeOutProducts
                        .map((eop) => eop.id)
                        .sort();

                    if (
                        !(
                            cashProductIds.every((cp) =>
                                newCashProductIds.includes(cp)
                            ) &&
                            gratisProductIds.every((gp) =>
                                newGratisProductIds.includes(gp)
                            ) &&
                            exchangeInProductIds.every((gp) =>
                                newExchangeInProductIds.includes(gp)
                            ) &&
                            exchangeOutProductIds.every((gp) =>
                                newExchangeOutProductIds.includes(gp)
                            )
                        )
                    ) {
                        this.setTaxValidation();
                    }
                }
            }
        });
    }

    async setTaxValidation(): Promise<void> {
        if (this.customerStateService.isTaxAvailable()) {
            (this.callService.call as RetailCall).shouldVisitTax = true;
            await this.callService.saveCallAndNotify();
        }
    }

    openQuantityWarning(
        product: CallProductOutViewModel | CallProductInViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the quantity higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.quantity = product.oldQuantity;
            this.overlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.overlayRef.close(data);
        };

        this.overlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.overlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallProductInViewModel:
                        if (product.oldQuantity === product.quantity) {
                            product.quantity++;
                        }

                        product.oldQuantity = product.quantity;
                        void this.saveProductIn(
                            product as CallProductInViewModel
                        );
                        break;

                    case product instanceof CallProductOutViewModel:
                        if (product.oldQuantity === product.quantity) {
                            product.quantity++;
                        }

                        product.oldQuantity = product.quantity;
                        void this.saveProductOut(
                            product as CallProductOutViewModel
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    }

    openUnitWarning(
        product: CallProductOutViewModel | CallProductInViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Sticks/Units higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.units = product.oldUnits;
            this.overlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.overlayRef.close(data);
        };

        this.overlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.overlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallProductInViewModel:
                        if (product.oldUnits === product.units) {
                            product.units++;
                        }

                        product.oldUnits = product.units;
                        void this.saveProductIn(
                            product as CallProductInViewModel
                        );
                        break;

                    case product instanceof CallProductOutViewModel:
                        if (product.oldUnits === product.units) {
                            product.units++;
                        }

                        product.oldUnits = product.units;
                        void this.saveProductOut(
                            product as CallProductOutViewModel
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    }

    openPriceWarning(
        product: CallProductOutViewModel | CallProductInViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Retail Price higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.price = product.oldRetailPrice ?? 0;
            this.overlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.overlayRef.close(data);
        };

        this.overlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.overlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallProductInViewModel:
                        product.oldRetailPrice = product.price;
                        void this.saveProductIn(
                            product as CallProductInViewModel
                        );
                        break;

                    case product instanceof CallProductOutViewModel:
                        product.oldRetailPrice = product.price;
                        void this.saveProductOut(
                            product as CallProductOutViewModel
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    }

    openWholesalePriceWarning(
        product: CallProductOutViewModel | CallProductInViewModel
    ): void {
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message =
            "You have set the Wholesale Price higher than the typical value. Are you sure you would like to proceed?";
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => {
            product.wholesalePrice = product.oldWholesalePrice ?? 0;
            this.overlayRef.close(data);
        };
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.overlayRef.close(data);
        };

        this.overlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.overlayRef.afterClosed$.subscribe((event) => {
            if (event && event.data && event.data.isConfirmed) {
                switch (true) {
                    case product instanceof CallProductInViewModel:
                        product.oldWholesalePrice = product.wholesalePrice;
                        void this.saveProductIn(
                            product as CallProductInViewModel
                        );
                        break;

                    case product instanceof CallProductOutViewModel:
                        product.oldWholesalePrice = product.wholesalePrice;
                        void this.saveProductOut(
                            product as CallProductOutViewModel
                        );
                        break;
                    default:
                        break;
                }
            }
        });
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }
}
