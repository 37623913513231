<div class="row">
    <div class="col-2">
        <b>
            <u> Taxes: </u>
        </b>
    </div>
    <div class="col-2">

    </div>
    <div class="col"></div>
</div>
<div class="row">
    <div class="col-12">
        <mat-card appearance="outlined">
            <table class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th colspan="3">
                            <div class="row">
                                <div class="col name-column-width no-padding padding-little-left">
                                    Cat
                                </div>
                                <div class="col center">
                                    <div class="row">
                                        <div class="col no-padding">
                                            Qty Ext
                                        </div>
                                    </div>
                                </div>
                                <div class="col no-padding padding-little-right">
                                    Price
                                </div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasState" colspan="2">
                            <div class="row">
                                <div class="col">
                                    State - {{ vm.stateName !== 'Kentucky' ? vm.stateName + '*' : vm.stateName }}
                                </div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasCounty" colspan="2" class="county-tax-width">
                            <div class="row">
                                <div class="col">County - {{ vm.county }}</div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasCity" colspan="2" class="city-tax-width">
                            <div class="row">
                                <div class="col">City - {{ vm.city }}</div>
                            </div>
                        </th>
                        <th>
                            <div class="row">
                                <div class="col">Total</div>
                            </div>
                            <div class="row">
                                <div class="col">Tax</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of vm.records">
                        <td colspan="3">
                            <div class="row">
                                <div class="col">
                                    {{ record.category }}
                                </div>
                                <div class="col">
                                    {{ record.quantityExtention }}
                                </div>
                                <div class="col">
                                    {{ record.price | precisionCurrency }}
                                </div>
                            </div>
                        </td>
                        <ng-container *ngIf="vm.hasState">
                            <td *ngIf="record.stateTax || record.stateTax === 0" colspan="2" class="county-tax-width">
                                <div class="row">
                                    <div class="col">
                                        <div>Rate</div>
                                        <div>{{ record.stateRate }} - {{ record.stateCalculationMethod }}</div>
                                    </div>
                                    <div class="col">
                                        <div *ngIf="vm.dollarsPerPack === record.stateCalculationMethod">Tax: {{ record.quantity * record.noOfPacks }} packs</div>
                                        <div *ngIf="vm.dollarsPerEach === record.stateCalculationMethod">Tax: {{ record.quantityExtention }} eaches</div>
                                        <div *ngIf="vm.dollarsPerOz === record.stateCalculationMethod">Tax: {{ record.weight }} oz per each</div>
                                        <div *ngIf="vm.percentOfPrice === record.stateCalculationMethod">Tax: {{ record.price * record.quantity | precisionCurrency }}</div>
                                        <div>{{ record.stateTax | precisionCurrency }}</div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!record.stateTax && record.stateTax !== 0" colspan="2" class="county-tax-width">
                                No Tax
                            </td>
                        </ng-container>
                        <ng-container *ngIf="vm.hasCounty">
                            <td *ngIf="record.countyTax || record.countyTax === 0" colspan="2" class="county-tax-width">
                                <div class="row">
                                    <div class="col">
                                        <div>Rate</div>
                                        <div>{{ record.countyRate }} - {{ record.countyCalculationMethod }}</div>
                                    </div>
                                    <div class="col">
                                        <div *ngIf="vm.dollarsPerPack === record.countyCalculationMethod">Tax: {{ record.quantity * record.noOfPacks }} packs</div>
                                        <div *ngIf="vm.dollarsPerEach === record.countyCalculationMethod">Tax: {{ record.quantityExtention }} eaches</div>
                                        <div *ngIf="vm.dollarsPerOz === record.countyCalculationMethod">Tax: {{ record.weight }} oz</div>
                                        <div *ngIf="vm.percentOfPrice === record.countyCalculationMethod">Tax: {{ record.price * record.quantity | precisionCurrency }}</div>
                                        <div>{{ record.countyTax | precisionCurrency }}</div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!record.countyTax && record.countyTax !== 0" colspan="2" class="county-tax-width">
                                No Tax
                            </td>
                        </ng-container>
                        <ng-container *ngIf="vm.hasCity">
                            <td *ngIf="record.cityTax || record.cityTax === 0" colspan="2" class="county-tax-width">
                                <div class="row">
                                    <div class="col">
                                        <div>Rate</div>
                                        <div>{{ record.cityRate }} - {{ record.cityCalculationMethod }}</div>
                                    </div>
                                    <div class="col">
                                        <div *ngIf="vm.dollarsPerPack === record.cityCalculationMethod">Tax: {{ record.quantity * record.noOfPacks }} packs</div>
                                        <div *ngIf="vm.dollarsPerEach === record.cityCalculationMethod">Tax: {{ record.quantityExtention }} eaches</div>
                                        <div *ngIf="vm.dollarsPerOz === record.cityCalculationMethod">Tax: {{ record.weight }} oz</div>
                                        <div *ngIf="vm.percentOfPrice === record.cityCalculationMethod">Tax: {{ record.price * record.quantity | precisionCurrency }}</div>
                                        <div>{{ record.cityTax | precisionCurrency }}</div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!record.cityTax && record.cityTax !== 0" colspan="2" class="county-tax-width">
                                No Tax
                            </td>
                        </ng-container>
                        <ng-container>
                            <td colspan="2" class="county-tax-width">
                                <div class="row">
                                    <div *ngIf="!record.stateTax && record.stateTax !== 0
                                        && !record.cityTax && record.cityTax !== 0
                                        && !record.countyTax && record.countyTax !== 0" class="col">No Tax</div>
                                </div>
                                <div *ngIf="record.stateTax || record.stateTax === 0
                                    || record.countyTax || record.countyTax === 0
                                    || record.cityTax || record.cityTax === 0" class="row">
                                    <div class="col">
                                        <div>{{ record.totalTax | precisionCurrency }}</div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </mat-card>
    </div>
</div>
<div class="row">
    <div class="col">
        <div *ngIf="vm.stateName !== 'Kentucky'" class="pull-left">
            <strong>*Note - State taxes here are advisory, as they are already included
            in the price.</strong>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-5">
        <div class="row">
            <div class="col-5">
                <mat-card appearance="outlined" *ngIf="vm.hasCounty" class="no-padding panel-height extra-light-gray">
                    <mat-card-content class="my-mat-content-custom-header">
                        <b>County Tax - Paid Via</b>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-7">
                                <div class="pull-left">{{ vm.countyTotal | precisionCurrency }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="margin-top"></div>
                            <div class="col h-center">

                                <mat-radio-group color="warn" class="payment-radio-group"
                                    [(ngModel)]="vm.selectedCountyPaymentType"
                                    (change)="vm.onSelectedCountyPaymentTypeChange()">
                                    <mat-radio-button class="payment-radio-button" *ngFor="
                                            let paymentMethod of vm.paymentOptions
                                        " [value]="paymentMethod">
                                        {{ paymentMethod }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="col-5">
        <div class="row">
            <div class="col-5">
                <mat-card appearance="outlined" *ngIf="vm.hasCity" class="no-padding panel-height extra-light-gray">
                    <mat-card-content class="my-mat-content-custom-header">
                        <b>City Tax - Paid Via</b>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-7">
                                <div class="pull-left">{{ vm.cityTotal | precisionCurrency }}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="margin-top"></div>
                            <div class="col h-center">
                                <mat-radio-group color="warn" class="payment-radio-group"
                                    [(ngModel)]="vm.selectedCityPaymentType"
                                    (change)="vm.onSelectedCityPaymentTypeChange()">
                                    <mat-radio-button class="payment-radio-button" *ngFor="
                                            let paymentMethod of vm.paymentOptions
                                        " [value]="paymentMethod">
                                        {{ paymentMethod }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="col-2 pull-right">
        <div class="row">
            <div class="col">
                <mat-card appearance="outlined" class="no-padding extra-light-gray">
                    <mat-card-content class="my-mat-content-custom-header">
                        <b>Total Tax</b>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="col">{{ vm.stateTotal + vm.cityTotal + vm.countyTotal  | currency }}</div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col margin-top"></div>
                            <div class="col-8"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
